import axios from "axios";

import { BASE_URL } from "../utils/assets";

const authService = {
  signinUSer: async (data) => {
    try {
      const response = await axios.post(`${BASE_URL}/signin`, data);

      return response.data;
    } catch (error) {
      throw { message: error.response.data };
    }
  },

  signupUser: async (data) => {
    try {
      const response = await axios.post(`${BASE_URL}/signup`, data);
      return response.data;
    } catch (error) {
      throw { message: error.response.data };
    }
  },

  ForgotPassword: async (email) => {
    try {
      // console.log("called=", email);
      const response = await axios.post(`${BASE_URL}/forgot_password`, {
        email,
      });
      // console.log("response.data for forgot=====", response);
      return response.data;
    } catch (error) {
      throw { message: error.response.data };
    }
  },

  resetPassword: async (token, password) => {
    try {
      const response = await axios.post(`${BASE_URL}/reset-password/${token}`, {
        password,
      });
      return response.data;
    } catch (error) {
      throw { message: error.response.data };
    }
  },
};

export default authService;

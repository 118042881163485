import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const RedirectComponent = () => {
  const { id } = useParams();

  useEffect(() => {
    const redirectToStripe = async () => {
      window.location.href = `https://buy.stripe.com/${id}`;
    };

    redirectToStripe();
  }, []);
  return <div>Redirecting...</div>;
};

export default RedirectComponent;

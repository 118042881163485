import axios from "axios";
import { BASE_URL } from "../utils/assets";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Define the API service
const customerService = {
  createNewCustomer: async (data) => {
    try {
      const token = localStorage.getItem("x-access-token");
      if (token) {
        axiosInstance.defaults.headers.common["x-access-token"] = token;
        const response = await axiosInstance.post(
          `${BASE_URL}/createCustomer`,
          data
        );
        return response.data;
      } else {
        // Handle the case when the token is not available (e.g., redirect to login)
        throw new Error("Token not available");
      }
    } catch (error) {
      throw { message: error.response.data };
    }
  },

  getAllCustomerByUserId: async () => {
    try {
      const token = localStorage.getItem("x-access-token");
      if (token) {
        axiosInstance.defaults.headers.common["x-access-token"] = token;
        const response = await axiosInstance.get("/getAllCustomerbyUserId");
        return response.data;
      } else {
        // Handle the case when the token is not available (e.g., redirect to login)
        throw new Error("Token not available");
      }
    } catch (error) {
      throw error.response.data;
    }
  },
};

export default customerService;

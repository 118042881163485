import {
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  GET_ALL_CUSTOMER_BYUSERID_REQUEST,
  GET_ALL_CUSTOMER_BYUSERID_SUCCESS,
  GET_ALL_CUSTOMER_BYUSERID_FAILURE,
  SET_SELECTED_BUYER,
} from "../constants/Customer.constants";

export const createCustomerRequest = () => {
  return {
    type: CREATE_CUSTOMER_REQUEST,
  };
};

export const createCustomerSuccess = (newCustomer) => {
  return {
    type: CREATE_CUSTOMER_SUCCESS,
    payload: newCustomer,
  };
};

export const createCustomerFailure = (error) => {
  return {
    type: CREATE_CUSTOMER_FAILURE,
    payload: error,
  };
};

export const getAllCustomerByUserIDRequest = () => {
  return {
    type: GET_ALL_CUSTOMER_BYUSERID_REQUEST,
  };
};

export const getAllCustomerByUserIDSuccess = (allCustomers) => {
  return {
    type: GET_ALL_CUSTOMER_BYUSERID_SUCCESS,
    payload: allCustomers,
  };
};

export const getAllCustomerByUserIDFailure = (error) => {
  return {
    type: GET_ALL_CUSTOMER_BYUSERID_FAILURE,
    payload: error,
  };
};

export const setSelectedBuyer = (selectedBuyer) => {
  return {
    type: SET_SELECTED_BUYER,
    payload: selectedBuyer,
  };
};

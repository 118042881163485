import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown/Dropdown";

import {
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserFailure,
  setShowUpdateUserForm,
} from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import userService from "../../services/userServices";
import EditProfileForm from "../EditProfileForm/EditProfileForm";
import moment from "moment";
const Profile = () => {
  const { currentUserInfo, upatedUserData, showUpdateUserForm, loading } =
    useSelector((state) => state.user);

  // console.log("currentuserInfo===", currentUserInfo);
  const dispatch = useDispatch();

  const getCurrentUserInfo = async () => {
    try {
      dispatch(getCurrentUserRequest());
      const response = await userService.getCurrentUserInfo();
      if (response.success === true) {
        dispatch(getCurrentUserSuccess(response.result));
      }
    } catch (err) {
      dispatch(getCurrentUserFailure(err.message));
    }
  };

  useEffect(
    (e) => {
      getCurrentUserInfo();
    },
    [upatedUserData]
  );

  if (loading) {
    return <div>Loading...</div>; // Replace this with your loader component
  }

  return (
    <div class="flex bg-white flex-col items-center justify-center">
      <div class="bg-white w-full rounded-lg shadow-xl pb-8">
        <div class="w-full h-[250px]">
          <img
            src="https://vojislavd.com/ta-template-demo/assets/img/profile-background.jpg"
            class="w-full h-full rounded-tl-lg rounded-tr-lg"
          />
        </div>
        <div class="flex flex-col items-center -mt-20">
          <img
            src="/images/dummyProfile.jpg"
            class="w-40 border-4 border-white rounded-full"
          />
          <div class="flex items-center space-x-2 mt-2">
            <p class="text-2xl">
              {currentUserInfo?.firstName} {currentUserInfo?.lastName}
            </p>
            <span class="bg-blue-500 rounded-full p-1" title="Verified">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="text-gray-100 h-2.5 w-2.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="4"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </span>
          </div>
          <p class="text-gray-700">{currentUserInfo?.email}</p>
          <p class="text-sm text-gray-500">
            {moment(currentUserInfo?.createdAt).format("DD MMM YYYY")}
          </p>
        </div>
        <div class="flex-1 flex flex-col items-center lg:items-end justify-end px-8 mt-2">
          <div class="flex items-center space-x-4 mt-2">
            <button
              onClick={(e) => dispatch(setShowUpdateUserForm(true))}
              class="flex items-center bg-blue-600 hover:bg-blue-700 text-gray-100 px-4 py-2 rounded text-sm space-x-2 transition duration-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"></path>
              </svg>
              <span>Edit Profile</span>
            </button>
          </div>
        </div>
      </div>

      {showUpdateUserForm === true && <EditProfileForm />}
      {/* user detail info */}
      <div class="my-4 flex bg-white w-full flex-col 2xl:flex-row space-y-4 2xl:space-y-0 2xl:space-x-4">
        <div class="w-full sm:w-full flex flex-col ">
          <div class="flex-1 bg-white w-full rounded-lg shadow-xl p-8">
            <h4 class="text-xl text-gray-900 font-bold">Personal Info</h4>
            <ul class="mt-2 text-gray-700">
              <li class="flex border-y py-2">
                <span class="font-bold w-24">Full name:</span>
                <span class="text-gray-700">
                  {" "}
                  {currentUserInfo?.firstName} {currentUserInfo?.lastName}
                </span>
              </li>
              <li class="flex border-b py-2">
                <span class="font-bold w-40">Bussiness name:</span>
                <span class="text-gray-700">
                  {currentUserInfo?.businessName}
                </span>
              </li>

              <li class="flex border-b py-2">
                <span class="font-bold w-40">Brand name:</span>
                <span class="text-gray-700">{currentUserInfo?.brandName}</span>
              </li>

              {/* <li class="flex border-b py-2">
                <span class="font-bold w-24">Joined:</span>
                <span class="text-gray-700">10 Jan 2022 (25 days ago)</span>
              </li> */}
              <li class="flex border-b py-2">
                <span class="font-bold w-24">Country:</span>
                <span class="text-gray-700">{currentUserInfo?.country}</span>
              </li>
              <li class="flex border-b py-2">
                <span class="font-bold w-24">Street:</span>
                <span class="text-gray-700">{currentUserInfo?.street}</span>
              </li>
              <li class="flex border-b py-2">
                <span class="font-bold w-24">City:</span>
                <span class="text-gray-700">{currentUserInfo?.city}</span>
              </li>
              <li class="flex border-b py-2">
                <span class="font-bold w-24">State:</span>
                <span class="text-gray-700">{currentUserInfo?.state}</span>
              </li>
              <li class="flex border-b py-2">
                <span class="font-bold w-28">Postal code:</span>
                <span class="text-gray-700">{currentUserInfo?.postalCode}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    // ==============================================
    // <div class="flex bg-white items-center justify-center w-full lg:p-7">
    //   <div class="flex w-full sm:w-auto justify-center items-center bg-white p-10  xl:p-5">
    //     <form class="flex sm:px-5 flex-col bg-white w-full h-full pb-6 text-center bg-white rounded-3xl">
    //       <h3 class="mb-1 text-xl font-extrabold text-dark-grey-500">
    //         Profile
    //       </h3>
    //       <button>edit</button>
    //       <div class="flex items-center mb-3">
    //         <hr class="h-0 border-b border-solid border-grey-500 grow" />
    //       </div>

    //       {/* {showAddBuyerForm === true && <AdNewBuyerForm />}
    //       {showExistingBuyer === true && <ExistingBuyers />}
    //       {Object.keys(selectedBuyer).length !== 0 && (
    //         <div className="mt-3">
    //           <DispayBuyerData />
    //         </div>
    //       )} */}

    //       {/* Transaction detail */}
    //       <div className="text-start flex flex-col ">
    //         {/* invoice amount */}
    //         <div>
    //           <div>
    //             <label
    //               for="amount"
    //               class="mb-2 text-sm text-start text-grey-900"
    //             >
    //               Currency
    //             </label>
    //             {/* <Dropdown
    //               items={currencies}
    //               onChange={handleCurrencyChange}
    //               value={formData?.currency?.toUpperCase()}
    //             /> */}
    //           </div>
    //           <div className="mb-3 sm:mb-3">
    //             <label
    //               for="amount"
    //               class="mb-2 text-sm text-start text-grey-900"
    //             >
    //               Invoice Amount
    //             </label>

    //             <input
    //               id="amount"
    //               type="number"
    //               name="amount"
    //               value={formData.amount}
    //               //   onChange={(e) => handleChange(e)}
    //               placeholder="Enter Amount"
    //               style={{ backgroundColor: "#F6F8FD" }}
    //               class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
    //             />
    //             {errors.amount && (
    //               <p className="text-xs flex items-start text-start text-red-500">
    //                 {errors.amount}
    //               </p>
    //             )}
    //           </div>
    //         </div>

    //         {/* Transaction Description*/}
    //         <div>
    //           <label
    //             for="description"
    //             class="mb-2 text-sm text-start text-grey-900"
    //           >
    //             Transaction Description
    //           </label>
    //           <input
    //             id="description"
    //             type="string"
    //             name="description"
    //             value={formData.description}
    //             // onChange={(e) => handleChange(e)}
    //             placeholder="Enter Description"
    //             style={{ backgroundColor: "#F6F8FD" }}
    //             class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100 mb-3 sm:mb-3 placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
    //           />
    //         </div>
    //       </div>

    //       <div className=" flex sm:flex-row sm:justify-center sm:gap-x-4">
    //         <button
    //           //   onClick={() => dispatch(setShowForm(false))}
    //           class="w-full px-6 py-2 mb-5 text-sm font-bold leading-none text-gray-500 transition duration-300 md:w-36 rounded-lg border hover:border-blue-600 focus:ring-4 focus:ring-blue-100 "
    //         >
    //           Cancel
    //         </button>
    //         <button
    //           onClick={(e) => {
    //             e.preventDefault();
    //             // handleformSubmit(e);
    //           }}
    //           class="w-full px-6 py-2 mb-5 text-sm font-bold leading-none text-white transition duration-300 md:w-36 rounded-lg hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 bg-blue-500"
    //         >
    //           Create
    //         </button>
    //       </div>
    //     </form>
    //   </div>
    // </div>
  );
};

export default Profile;

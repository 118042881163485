import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Dropdown from "../Dropdown/Dropdown";
import customerService from "../../services/customerService";
import {
  getAllCustomerByUserIDRequest,
  getAllCustomerByUserIDSuccess,
  getAllCustomerByUserIDFailure,
  setSelectedBuyer,
} from "../../actions/customerActions";
import { setShowExistingBuyer } from "../../actions/dropdownActions";
import { useDispatch, useSelector } from "react-redux";

export default function ExistingBuyers() {
  const { allCustomer } = useSelector((state) => state.customer);
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const cancelButtonRef = useRef(null);

  const getAllBuyersOfCurrentUser = async () => {
    try {
      dispatch(getAllCustomerByUserIDRequest());
      const response = await customerService.getAllCustomerByUserId();
      if (response.success === true) {
        dispatch(getAllCustomerByUserIDSuccess(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAllCustomerByUserIDFailure(err.message));
    }
  };

  useEffect(() => {
    getAllBuyersOfCurrentUser();
  }, []);

  const handleCancelClick = () => {
    dispatch(setShowExistingBuyer(false));
  };

  const handleChange = (e) => {
    setSearchInput(e.target.value); // Update search input value
  };

  const handleItemClick = (selectedItem) => {
    dispatch(setSelectedBuyer(selectedItem));
    dispatch(setShowExistingBuyer(false));
  };

  const filteredCustomers = allCustomer.filter((item) =>
    item.email.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(setShowExistingBuyer(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 sm:w-full  text-center  sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Existing User
                      </Dialog.Title>
                      <div className="mt-2 w-full ">
                        {/* search component */}
                        <div className=" ">
                          <form class="">
                            <label
                              for="default-search"
                              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                            >
                              Search
                            </label>
                            <div class="relative  w-100">
                              <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg
                                  class="w-4 h-4 text-gray-500 dark:text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                  />
                                </svg>
                              </div>
                              <input
                                type="search"
                                id="default-search"
                                class="block w-full p-3 ps-10 text-sm text-gray-900 border  rounded-lg bg-gray-50  focus:outline-none   dark:placeholder-gray-400 dark:text-gray-600  "
                                placeholder="Search buyer"
                                required
                                value={searchInput}
                                onChange={handleChange}
                              />
                              {/* <button
                                type="submit"
                                class="text-white absolute end-2 bottom-[0.55rem] bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              >
                                Search
                              </button> */}
                            </div>
                          </form>
                        </div>

                        <div className="mt-2 max-h-52 overflow-y-scroll">
                          {filteredCustomers.length === 0 ? (
                            <div className="text-red-300 text-center text-sm">
                              No buyer found !
                            </div>
                          ) : (
                            filteredCustomers.map((item) => (
                              <div
                                className="mb-2"
                                key={item._id}
                                onClick={() => handleItemClick(item)}
                              >
                                <div className="p-3 w-full border hover:bg-blue-500 hover:text-white rounded-lg text-sm">
                                  Email : {item.email}
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {/* <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 sm:ml-3 sm:w-auto"
                    onClick={handleformSubmit}
                  >
                    Select
                  </button> */}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border hover:border-blue-600 sm:mt-0 sm:w-auto focus:ring-4 focus:ring-blue-100"
                    onClick={handleCancelClick}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

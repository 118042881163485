import React from "react";

import { IoMdNotifications } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";
import sidebarItems from "../../utils/sidebarItems";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShowForm } from "../../actions/dropdownActions";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage?.removeItem("x-access-token");
    localStorage?.removeItem("userType");
    navigate("/signin");
  };

  const handleAllTransactionsClick = () => {
    dispatch(setShowForm(false));
    navigate("/admin/dashboard");
  };
  return (
    <div
      style={{ backgroundColor: "#F1F5F9" }}
      class="min-h-screen flex flex-row border-r bg-white"
    >
      <div
        style={{ backgroundColor: "#F1F5F9" }}
        class="flex flex-col w-56  overflow-hidden"
      >
        <div class="flex items-center justify-center h-20 shadow-md">
          <h1 class="text-xl  text-indigo-500">fastpagos</h1>
        </div>
        <ul class="flex flex-col py-4 hover:text-gray-800">
          {sidebarItems.map((item) => (
            <li>
              <NavLink
                to={item.path}
                onClick={
                  item.name === "All Transaction"
                    ? handleAllTransactionsClick
                    : null
                }
                class="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-gray-800"
              >
                <span class="inline-flex items-center justify-center h-12 w-12 text-lg text-gray-400 hover:text-gray-800">
                  {/* <i class="bx bx-home"></i> */}
                  {item.icon}
                </span>
                <span class="text-sm font-medium hover:font-semibold">
                  {item.name}
                </span>
              </NavLink>
            </li>
          ))}

          <li onClick={logout}>
            <a
              href="#"
              class="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-500 hover:text-gray-800"
            >
              <span class="inline-flex items-center justify-center h-12 w-12 text-lg text-gray-400 ">
                <i class="bx bx-log-out"></i>
                <IoLogOut />
              </span>
              <span class="text-sm font-medium hover:font-semibold">
                Logout
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useEffect } from "react";
import "./App.css";
import "./tailwind.css";

import { Route, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import AdminScreen from "./screens/adminScreen/AdminScreen";
import SigninScreen from "./screens/signInScreen/SigninScreen";
import AdminDashboardManagement from "./components/adminDashboardManagement/AdminDashboardManagement";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import RedirectComponent from "./screens/paymentRedirect/paymentRedirect";
import TermsScreen from "./screens/termsScreen/TermsScreen";
import PolicyScreen from "./screens/policyScreen/PolicyScreen";
import EmailVerification from "./screens/emailVerification/EmailVerification";
import ProfileManagement from "./components/ProfileManagement/ProfileManagement";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem("x-access-token");
  useEffect(() => {
    // Redirect to appropriate pages based on authentication status and current path
    if (
      !user &&
      location.pathname !== "/signin" &&
      !location.pathname.startsWith("/reset_password/") &&
      location.pathname !== "/terms" &&
      location.pathname !== "/policy" &&
      location.pathname.startsWith("/verify-email")
    ) {
      navigate("/signin");
    } else if (user && location.pathname === "/signin") {
      navigate("/admin/dashboard");
    } else if (location.pathname === "/") {
      navigate("/signin"); // Redirect from root route to sign-in page
    } else if (user && location.pathname.startsWith("/reset_password/")) {
      navigate("/admin/dashboard"); // Redirect authenticated users away from reset_password route
    } else if (user && location.pathname.startsWith("/verify-email/")) {
      navigate("/admin/dashboard");
    }
  }, [user, location, navigate]);

  return (
    <div className="App">
      <Routes>
        <Route path="/signin" element={<SigninScreen />} />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
        <Route path="/:id" element={<RedirectComponent />} />
        <Route path="/terms" element={<TermsScreen />} />
        <Route path="/policy" element={<PolicyScreen />} />
        <Route path="/verify-email/:token" element={<EmailVerification />} />

        <Route path="/admin/*" element={user ? <AdminScreen /> : <Navigate to="/signin" />}>
          {user && (
            <>
              <Route path="dashboard" element={<AdminDashboardManagement />} />
              <Route path="profile" element={<ProfileManagement />} />
            </>
          )}
        </Route>
      </Routes>
    </div>
  );
}

export default App;

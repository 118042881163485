import React from "react";
import "./adminScreen.css";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../../components/SideBar/Sidebar";
import TransactionManagement from "../../components/transactionManagement/TransactionManagement";

const AdminScreen = () => {
  // const sidebarVisible = useSelector((state) => state.sidebar.sidebarVisible);

  return (
    <div
      // style={{ backgroundColor: "#FBFDFE" }}
      className="main-admin h-full"
    >
      <div className="sidebar min-w-48">
        <Sidebar />
      </div>
      <div className="outlook-cont">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminScreen;

import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Dropdown from "../Dropdown/Dropdown";
import customerService from "../../services/customerService";
import {
  createCustomerRequest,
  createCustomerSuccess,
  createCustomerFailure,
  setSelectedBuyer,
} from "../../actions/customerActions";
import { setShowAddBuyerForm } from "../../actions/dropdownActions";
import { newBuyerFormSchema } from "../../utils/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

const entityType = [
  { id: 1, name: "Business" },
  { id: 2, name: "Individual" },
];

const country = [
  { id: 1, name: "India" },
  { id: 2, name: "United States" },
  { id: 3, name: "China" },
  { id: 4, name: "Indonesia" },
  { id: 5, name: "Pakistan" },
  { id: 6, name: "Brazil" },
  { id: 7, name: "Nigeria" },
  { id: 8, name: "Bangladesh" },
  { id: 9, name: "Russia" },
  { id: 10, name: "Mexico" },
  { id: 11, name: "Japan" },
  { id: 12, name: "Ethiopia" },
  { id: 13, name: "Philippines" },
  { id: 14, name: "Egypt" },
  { id: 15, name: "Vietnam" },
  { id: 16, name: "DR Congo" },
  { id: 17, name: "Turkey" },
  { id: 18, name: "Iran" },
  { id: 19, name: "Germany" },
  { id: 20, name: "Thailand" },
  { id: 21, name: "France" },
  { id: 22, name: "United Kingdom" },
  { id: 23, name: "Italy" },
  { id: 24, name: "South Africa" },
  { id: 25, name: "Tanzania" },
  { id: 26, name: "Myanmar" },
  { id: 27, name: "South Korea" },
  { id: 28, name: "Colombia" },
  { id: 29, name: "Kenya" },
  { id: 30, name: "Spain" },
];

export default function AdNewBuyerForm() {
  const initialState = {
    entityType: "Business",
    country: "India",
    street: "",
    city: "",
    postalCode: "",
    state: "",
    companyName: "",
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  };

  const [formData, setFormData] = useState(initialState);
  const { showAddBuyerForm } = useSelector((state) => state.dropdowns);
  const [errors, setErrors] = useState({});

  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const cancelButtonRef = useRef(null);

  const validateField = async (name, value) => {
    try {
      await Yup.reach(newBuyerFormSchema, name).validate(value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleCountryChange = (country) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      country: country,
    }));
  };

  const handleEntityChange = (entity) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      entityType: entity,
    }));
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));

    validateField(e.target.name, e.target.value);
  };

  const handleformSubmit = async (e) => {
    e.preventDefault();

    try {
      await newBuyerFormSchema.validate(formData, { abortEarly: false });

      dispatch(createCustomerRequest());
      const response = await customerService.createNewCustomer(formData);
      if (response.success === true) {
        dispatch(createCustomerSuccess(response.data));
        dispatch(setSelectedBuyer(response.data));
        dispatch(setShowAddBuyerForm(false));
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      } else {
        dispatch(createCustomerFailure(err.message));
      }
    }
  };

  const closedialog = () => {
    dispatch(setShowAddBuyerForm(false));
  };

  return (
    <Transition.Root show={showAddBuyerForm} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closedialog}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Add Buyer
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="text-start flex flex-col">
                          {/* Business Registration Country */}
                          <div className="sm:mb-3">
                            <label
                              for="entityType"
                              class="mb-2 text-sm text-start text-grey-900"
                            >
                              Entity Type*
                            </label>

                            <Dropdown
                              onChange={handleEntityChange}
                              items={entityType}
                            />
                          </div>

                          {/*  Country */}
                          <div className="sm:mb-3">
                            <label
                              for="businessRegCountry"
                              class="mb-2 text-sm text-start text-grey-900"
                            >
                              Country*
                            </label>

                            <Dropdown
                              onChange={handleCountryChange}
                              items={country}
                            />
                          </div>

                          {/* Business name */}
                          <div className="flex flex-col mb-3 sm:mb-3">
                            <label
                              for="companyName"
                              class="mb-2 text-sm text-start text-grey-900"
                            >
                              Company Name*
                            </label>
                            <input
                              autocomplete="off"
                              id="companyName"
                              type="string"
                              name="companyName"
                              value={formData.companyName}
                              onChange={(e) => handleChange(e)}
                              placeholder="Enter Company Name"
                              style={{ backgroundColor: "#F6F8FD" }}
                              class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                            />
                            {errors.companyName && (
                              <p className="text-xs flex items-start text-start text-red-500">
                                {errors.companyName}
                              </p>
                            )}
                          </div>

                          {/* Customer Id */}
                          <div className="mb-3 sm:mb-3">
                            <label
                              for="companyName"
                              class="mb-2 text-sm text-start text-grey-900"
                            >
                              Customer Id*
                            </label>
                            <input
                              autocomplete="off"
                              id="customerId"
                              type="string"
                              name="customerId"
                              value={formData.customerId}
                              onChange={(e) => handleChange(e)}
                              placeholder="Enter Customer Id"
                              style={{ backgroundColor: "#F6F8FD" }}
                              class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                            />
                            {errors.customerId && (
                              <p className="text-xs flex items-start text-start text-red-500">
                                {errors.customerId}
                              </p>
                            )}
                          </div>

                          {/* first name/ last name */}
                          <div className="sm:flex  sm:flex-row gap-x-3">
                            {/* first Name */}

                            <div className="mb-3 sm:mb-3">
                              <label
                                for="first name"
                                class="mb-2 text-sm text-start text-grey-900"
                              >
                                First Name
                              </label>
                              <input
                                autocomplete="off"
                                id="firstName"
                                type="name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={(e) => handleChange(e)}
                                placeholder="John"
                                style={{ backgroundColor: "#F6F8FD" }}
                                class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                              />
                              {/* {errors.firstName && (
                                <p className="text-xs flex items-start text-start text-red-500">
                                  {errors.firstName}
                                </p>
                              )} */}
                            </div>

                            <div className="mb-3 sm:mb-3">
                              {/* last Name */}
                              <label
                                for="last name"
                                class="mb-2 text-sm text-start text-grey-900"
                              >
                                Last Name
                              </label>
                              <input
                                autocomplete="off"
                                id="lastName"
                                type="name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={(e) => handleChange(e)}
                                placeholder="levon"
                                style={{ backgroundColor: "#F6F8FD" }}
                                class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                              />
                              {/* {errors.lastName && (
                                <p className="text-xs flex items-start text-start text-red-500">
                                  {errors.lastName}
                                </p>
                              )} */}
                            </div>
                          </div>

                          {/* email/ phone number */}

                          <div className="sm:flex sm:flex-row gap-x-3">
                            <div className="mb-3 ">
                              {/* email */}
                              <label
                                for="email"
                                class="mb-2 text-sm text-start text-grey-900"
                              >
                                Email*
                              </label>
                              <input
                                autocomplete="off"
                                id="email"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={(e) => handleChange(e)}
                                placeholder="mail@loopple.com"
                                style={{ backgroundColor: "#F6F8FD" }}
                                class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                              />
                              {errors.email && (
                                <p className="text-xs flex items-start text-start text-red-500">
                                  {errors.email}
                                </p>
                              )}
                            </div>

                            {/* phoneNumber */}
                            <div className="mb-3 ">
                              <label
                                for="phoneNumber"
                                class="mb-2 text-sm text-start text-grey-900"
                              >
                                {" Contact Number (Optional)"}
                              </label>
                              <input
                                autocomplete="off"
                                id="phoneNumber"
                                type="number"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter a contact number"
                                style={{ backgroundColor: "#F6F8FD" }}
                                class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100   placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                              />
                            </div>
                          </div>

                          {/* street / city */}
                          <div className="sm:flex  sm:flex-row gap-x-3">
                            {/* street */}

                            <div className="mb-3 ">
                              <label
                                for="street"
                                class="mb-2 text-sm text-start text-grey-900"
                              >
                                Street
                              </label>
                              <input
                                autocomplete="off"
                                id="street"
                                type="string"
                                name="street"
                                value={formData.street}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter street "
                                style={{ backgroundColor: "#F6F8FD" }}
                                class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                              />
                              {/* {errors.street && (
                                <p className="text-xs flex items-start text-start text-red-500">
                                  {errors.street}
                                </p>
                              )} */}
                            </div>

                            <div className="mb-3 ">
                              {/* city */}
                              <label
                                for="city"
                                class="mb-2 text-sm text-start text-grey-900"
                              >
                                City
                              </label>
                              <input
                                autocomplete="off"
                                id="city"
                                type="string"
                                name="city"
                                value={formData.city}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter city name"
                                style={{ backgroundColor: "#F6F8FD" }}
                                class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                              />
                              {/* {errors.city && (
                                <p className="text-xs flex items-start text-start text-red-500">
                                  {errors.city}
                                </p>
                              )} */}
                            </div>
                          </div>

                          {/* state / postal code */}
                          <div className="sm:flex  sm:flex-row gap-x-3">
                            {/* state */}

                            <div className="mb-3 sm:mb-3">
                              <label
                                for="state"
                                class="mb-2 text-sm text-start text-grey-900"
                              >
                                State
                              </label>
                              <input
                                autocomplete="off"
                                id="state"
                                type="string"
                                name="state"
                                value={formData.state}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter state name "
                                style={{ backgroundColor: "#F6F8FD" }}
                                class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                              />
                              {/* {errors.state && (
                                <p className="text-xs flex items-start text-start text-red-500">
                                  {errors.state}
                                </p>
                              )} */}
                            </div>

                            <div className="mb-3 sm:mb-3">
                              {/* postal code */}
                              <label
                                for="postalCode"
                                class="mb-2 text-sm text-start text-grey-900"
                              >
                                Postal Code
                              </label>
                              <input
                                autocomplete="off"
                                id="postalCode"
                                type="string"
                                name="postalCode"
                                value={formData.postalCode}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter postal code"
                                style={{ backgroundColor: "#F6F8FD" }}
                                class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                              />
                              {/* {errors.postalCode && (
                                <p className="text-xs flex items-start text-start text-red-500">
                                  {errors.postalCode}
                                </p>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 sm:ml-3 sm:w-auto"
                    onClick={handleformSubmit}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border hover:border-blue-600 sm:mt-0 sm:w-auto focus:ring-4 focus:ring-blue-100"
                    onClick={(e) => dispatch(setShowAddBuyerForm(false))}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import {
  SET_COUNTRY_NAME,
  SET_SHOW_FORM,
  SET_SHOW_ADDBUYER_FORM,
  SET_SHOW_EXISTING_BUYER,
  SET_SHOW_PAYMENTLINK,
} from "../constants/Dropdown.contants";

const initialState = {
  selectedCountry: "India",
  showForm: false,
  showAddBuyerForm: false,
  showExistingBuyer: false,
  showPaymentLink: false,
};

export const dropDownReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_NAME:
      return {
        ...state,
        selectedCountry: action.payload,
      };

    case SET_SHOW_FORM:
      return {
        ...state,
        showForm: action.payload,
      };

    case SET_SHOW_ADDBUYER_FORM:
      return {
        ...state,
        showAddBuyerForm: action.payload,
      };

    case SET_SHOW_EXISTING_BUYER:
      return {
        ...state,
        showExistingBuyer: action.payload,
      };

    case SET_SHOW_PAYMENTLINK:
      return {
        ...state,
        showPaymentLink: action.payload,
      };

    default:
      return state;
  }
};

import {
  GET_ALLPAYMENTS_FAILURE,
  GET_ALLPAYMENTS_REQUEST,
  GET_ALLPAYMENTS_SUCCESS,
  CREATE_PAYMENTS_REQUEST,
  CREATE_PAYMENTS_SUCCESS,
  CREATE_PAYMENTS_FAILURE,
} from "../constants/Payments.constants";

export const getAllPaymentsRequest = () => {
  return {
    type: GET_ALLPAYMENTS_REQUEST,
  };
};

export const getAllPaymentsSuccess = (payments) => {
  return {
    type: GET_ALLPAYMENTS_SUCCESS,
    payload: payments,
  };
};

export const getAllPaymentsFailure = (error) => {
  return {
    type: GET_ALLPAYMENTS_FAILURE,
    payload: error,
  };
};

export const createPaymentsRequest = () => {
  return {
    type: CREATE_PAYMENTS_REQUEST,
  };
};

export const createPaymentsSuccess = (payment) => {
  return {
    type: CREATE_PAYMENTS_SUCCESS,
    payload: payment,
  };
};

export const createPaymentsFailure = (error) => {
  return {
    type: CREATE_PAYMENTS_FAILURE,
    payload: error,
  };
};

import * as Yup from "yup";

export const signinSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const signupSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must lowercase letter")
    .matches(/[A-Z]/, "Password must uppercase letter")
    .matches(/[0-9]/, "Password must number")
    .matches(/[!@#$%^&*()_+]/, "Password must contain special character"),
  bussinessName: Yup.string().required("Business Name is required"),
  businessRegCountry: Yup.string().required(
    "Business Registration Country is required"
  ),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must lowercase letter")
    .matches(/[A-Z]/, "Password must uppercase letter")
    .matches(/[0-9]/, "Password must number")
    .matches(/[!@#$%^&*()_+]/, "Password must contain special character"),
});

export const newBuyerFormSchema = Yup.object().shape({
  companyName: Yup.string().required("Please enter company name"),
  customerId: Yup.string().required("Customer Id is required"),
  // firstName: Yup.string().required("Please enter first name"),
  // lastName: Yup.string().required("Please enter last name"),
  email: Yup.string().email("Invalid email").required("Email is required"),
 
  // city: Yup.string().required("Please enter city name"),
  // street: Yup.string().required("Please enter street name"),
  // postalCode: Yup.string().required("Please enter postal code"),
  // state: Yup.string().required("Please enter state name"),
});

export const newPaymentLinkFormSchema = Yup.object().shape({
  amount: Yup.string().required("Please Enter amount."),
  description : Yup.string().required("Please provide a description.")
});

export const forgotPassSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

import { TbTransfer } from "react-icons/tb";
import { FaTableList } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
const sidebarItems = [
  {
    path: "/admin/dashboard",
    name: "All Transaction",
    icon: <TbTransfer />,
  },
  {
    path: "/admin/profile",
    name: "Profile",
    icon: <FaUser />,
  },
];
export default sidebarItems;

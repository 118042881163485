// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-admin {
  display: flex;
  justify-content: flex-start;
}

.sidebar {
  /* Add your sidebar styles here */
  position: sticky;
  top: 0;
  height: 100vh; /* Adjust the height as needed */
  width: 250px; /* Set the width of your sidebar */
  /* background-color: #fff; Set your desired background color */
  overflow-y: auto;
  /* border-right: 1px solid #ccc;  */
  z-index: 1; /* Ensure the sidebar is above the content */
  background-color: rgb(229, 235, 241);
}

.outlook-cont {
  /* Add your content area styles here */
  flex-grow: 1;
  padding: 20px; /* Add padding or adjust as needed */
  overflow-y: auto;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

}
`, "",{"version":3,"sources":["webpack://./src/screens/adminScreen/adminScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,MAAM;EACN,aAAa,EAAE,gCAAgC;EAC/C,YAAY,EAAE,kCAAkC;EAChD,8DAA8D;EAC9D,gBAAgB;EAChB,mCAAmC;EACnC,UAAU,EAAE,4CAA4C;EACxD,oCAAoC;AACtC;;AAEA;EACE,sCAAsC;EACtC,YAAY;EACZ,aAAa,EAAE,oCAAoC;EACnD,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;;AAEF","sourcesContent":[".main-admin {\n  display: flex;\n  justify-content: flex-start;\n}\n\n.sidebar {\n  /* Add your sidebar styles here */\n  position: sticky;\n  top: 0;\n  height: 100vh; /* Adjust the height as needed */\n  width: 250px; /* Set the width of your sidebar */\n  /* background-color: #fff; Set your desired background color */\n  overflow-y: auto;\n  /* border-right: 1px solid #ccc;  */\n  z-index: 1; /* Ensure the sidebar is above the content */\n  background-color: rgb(229, 235, 241);\n}\n\n.outlook-cont {\n  /* Add your content area styles here */\n  flex-grow: 1;\n  padding: 20px; /* Add padding or adjust as needed */\n  overflow-y: auto;\n}\n\n@media (max-width: 768px) {\n  .sidebar {\n    display: none;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { setShowForm } from "../../actions/dropdownActions";
import { useDispatch, useSelector } from "react-redux";
import paymentService from "../../services/paymentService";
import {
  getAllPaymentsRequest,
  getAllPaymentsSuccess,
  getAllPaymentsFailure,
  createPaymentsRequest,
  createPaymentsSuccess,
  createPaymentsFailure,
} from "../../actions/paymentActions";
import { setShowPaymentLink } from "../../actions/dropdownActions";
import AdNewBuyerForm from "../AddNewBuyerForm/AdNewBuyerForm";
import ExistingBuyers from "../ExistingBuyers/ExistingBuyers";
import DispayBuyerData from "../DisplayBuyerData/DispayBuyerData";
import { setShowAddBuyerForm } from "../../actions/dropdownActions";
import { MdOutlineVerified } from "react-icons/md";
import { setShowExistingBuyer } from "../../actions/dropdownActions";
import ShowPaymentLink from "../ShowPaymentLink/ShowPaymentLink";
import { newPaymentLinkFormSchema } from "../../utils/validationSchema";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";

const entityType = [
  { id: 1, name: "Business" },
  { id: 2, name: "Individual" },
];

const currencies = [
  { id: 1, name: "USD", country: "United States" },
  { id: 2, name: "EUR", country: "Eurozone" },
  { id: 3, name: "GBP", country: "United Kingdom" },
  { id: 4, name: "CAD", country: "Canada" },
  { id: 5, name: "AUD", country: "Australia" },
  { id: 6, name: "JPY", country: "Japan" },
  { id: 7, name: "CNY", country: "China" },
  { id: 8, name: "CHF", country: "Switzerland" },
  { id: 9, name: "BRL", country: "Brazil" },
  { id: 10, name: "INR", country: "India" },
  { id: 11, name: "RUB", country: "Russia" },
  { id: 12, name: "KRW", country: "South Korea" },
  { id: 13, name: "MXN", country: "Mexico" },
  { id: 14, name: "SEK", country: "Sweden" },
  { id: 15, name: "NOK", country: "Norway" },
  { id: 16, name: "DKK", country: "Denmark" },
  { id: 17, name: "SGD", country: "Singapore" },
  { id: 18, name: "HKD", country: "Hong Kong" },
  { id: 19, name: "ARS", country: "Argentina" },
  { id: 20, name: "PLN", country: "Poland" },
  { id: 21, name: "IDR", country: "Indonesia" },
  { id: 22, name: "TRY", country: "Turkey" },
  { id: 23, name: "ZAR", country: "South Africa" },
];


const paymentModes = [
  { id: 1, name: "Net_Banking_Only" },
  { id: 2, name: "All_Payment_Methods" },
  // { id: 3, name: "Virtual Bank Account", paymentMethod: ["vba"] }
];
const NewTransactionForm = () => {
  const initialState = {
    customerId: "",
    currency: "USD",
    amount: "",
    description: "",
    paymentMode: "All_Payment_Methods"
  };

  const dispatch = useDispatch();
  const { showAddBuyerForm, showExistingBuyer, showPaymentLink } = useSelector(
    (state) => state.dropdowns
  );
  const { selectedBuyer } = useSelector((state) => state.customer);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState(initialState);

  const validateField = async (name, value) => {
    try {
      await Yup.reach(newPaymentLinkFormSchema, name).validate(value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
      customerId: selectedBuyer._id,
    }));
    validateField(e.target.name, e.target.value);
  };

  const handleCurrencyChange = (currency) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      currency: currency.toLowerCase(),
    }));
  };

  const handlePaymentModeChange = (paymentMode) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      paymentMode: paymentMode.toLowerCase(),
    }));
  };

  const handleformSubmit = async (e) => {
    e.preventDefault();
    if (!selectedBuyer || Object.keys(selectedBuyer).length === 0) {
      toast.error("Please select a buyer or add new");
      return; // Exit the function early
    }
    try {
      await newPaymentLinkFormSchema.validate(formData, { abortEarly: false });

      const response = await paymentService.createNewPayment(formData);

      if (response.success === true) {
        dispatch(setShowForm(false));
        dispatch(createPaymentsSuccess(response.data));
        dispatch(setShowPaymentLink(true));

        fetchPaymentData();
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  const fetchPaymentData = async () => {
    try {
      dispatch(getAllPaymentsRequest());

      const response = await paymentService.getAllPaymentsByUserId();
      if (response.success === true) {
        dispatch(getAllPaymentsSuccess(response.data));
      }
    } catch (err) {
      dispatch(getAllPaymentsFailure(err.message));
    }
  };

  useEffect(() => {
    fetchPaymentData();
  }, []);

  // Find the currency object that matches the selectedBuyer.country
  const selectedCountryCurrency = currencies.find(
    (currency) => currency.country === selectedBuyer.country
  );

  // Set the default currency to the name of the currency found
  useEffect(() => {
    if (selectedCountryCurrency) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        currency: selectedCountryCurrency.name.toLowerCase(),
      }));
    }
  }, [selectedBuyer]);
  

  return (
    <div
      style={{ backgroundColor: "#F1F5F9" }}
      class="flex  items-center justify-start w-full "
    >
      <div
        style={{ backgroundColor: "#F1F5F9" }}
        class="flex w-full sm:w-full justify-center items-center  "
      >
        <form
          style={{ backgroundColor: "#F1F5F9" }}
          class="flex sm:px-5 flex-col bg-white w-full h-full pb-6 p-3 text-start bg-white "
        >
          <h3 class="mb-1 text-xl font-extrabold text-dark-grey-500">
            Create Payment Link
          </h3>
          <p class="mb-1 mt-4 text-gray-500 text-sm">1. Buyer Details</p>
          <div class="flex items-center mb-3">
            <hr class="h-0 border-b border-solid border-grey-500 grow" />
          </div>
          {/* buyer business info */}

          <div className="flex bg-white flex-col p-3 rounded-lg border ">
            <div className=" flex sm:flex-row gap-5">
              <div
                onClick={(e) => dispatch(setShowAddBuyerForm(true))}
                className="p-2 cursor-pointer shadow-md text-blue-500 bg-white rounded-md sm:text-[0.8rem]"
              >
                +Add buyer details
              </div>
              <div
                onClick={(e) => dispatch(setShowExistingBuyer(true))}
                className="p-2 cursor-pointer shadow-md flex flex-row items-center gap-x-1 sm:flex-row text-blue-500 bg-white rounded-md sm:text-[0.8rem]"
              >
                <MdOutlineVerified /> Existing buyers
              </div>
            </div>
            <div>
              {showAddBuyerForm === true && <AdNewBuyerForm />}
              {showExistingBuyer === true && <ExistingBuyers />}
              {Object.keys(selectedBuyer).length !== 0 && (
                <div className="mt-3">
                  <DispayBuyerData />
                </div>
              )}
            </div>
          </div>

          <p class="mb-1 mt-4 text-gray-500 text-sm mt-5">
            2. Transaction Details
          </p>

          {/* horizontal line */}
          <div class="flex items-center mb-3">
            <hr class="h-0 border-b border-solid border-grey-500 grow" />
          </div>

          {/* Transaction detail */}
          <div className="text-start bg-white p-3 border rounded-lg flex flex-col">
            {/* invoice amount */}
            <div>
              <div className="flex items-center mb-2 flex-row gap-44">
                <label for="amount" class="text-sm text-start text-grey-900">
                  Currency
                </label>
                <Dropdown
                  items={currencies}
                  onChange={handleCurrencyChange}
                  value={formData.currency.toUpperCase()}
                />
              </div>
              <div className="mb-3 flex flex-row items-center gap-32 sm:mb-3">
                <label for="amount" class="mb-2 text-sm text-start text-grey-900">
                  Invoice Amount
                </label>
                <div className="flex flex-col w-5/12">
                  <input
                    autocomplete="off"
                    id="amount"
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Amount"
                    style={{ backgroundColor: "#F6F8FD" }}
                    class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100 placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                  />
                  {errors.amount && (
                    <p className="text-xs flex items-start text-start text-red-500">
                      {errors.amount}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Transaction Description */}
            <div className="flex items-center flex-row gap-16 mb-3">
              <label for="description" class="mb-2 text-sm text-start text-grey-900">
                Transaction Description
              </label>
              <div className="flex flex-col w-5/12">
                <input
                  id="description"
                  type="string"
                  name="description"
                  value={formData.description}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter Description"
                  style={{ backgroundColor: "#F6F8FD" }}
                  class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100 placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                />
                {errors.description && (
                  <p className="text-xs flex ml-2 items-start text-start text-red-500">
                    {errors.description}
                  </p>
                )}
              </div>
            </div>

            {/* Payment Mode */}
            <div className="flex items-center flex-row gap-32">
              <label for="transactioninfo" class="text-sm text-start text-grey-900">
                Payment Mode
              </label>
              <Dropdown
                items={paymentModes}
                onChange={handlePaymentModeChange}
                value={formData.paymentMode.toUpperCase()}
              />
            </div>
          </div>

          <div className=" mt-4 flex sm:flex-row sm:justify-center sm:gap-x-4">
            <button
              onClick={() => dispatch(setShowForm(false))}
              class="w-full bg-white px-6 py-2 mb-5 text-sm font-bold leading-none text-gray-500 transition duration-300 md:w-36 rounded-lg border hover:border-blue-600 focus:ring-4 focus:ring-blue-100 "
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleformSubmit(e);
              }}
              class="w-full px-6 py-2 mb-5 text-sm font-bold leading-none text-white transition duration-300 md:w-36 rounded-lg hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 bg-blue-500"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewTransactionForm;

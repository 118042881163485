import React from "react";
import Profile from "../Profile/Profile";
import "./profileManagemenet.css";
import ProfileComponentsManage from "../ProfileComponentsManage/ProfileComponentsManage";

const ProfileManagement = () => {
  return (
    <div
      style={{ backgroundColor: "#FBFDFE" }}
      className="badminDasboard_main flex flex-col align-center "
    >
      <ProfileComponentsManage />
    </div>
  );
};

export default ProfileManagement;

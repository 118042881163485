import React from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Dropdown from "../Dropdown/Dropdown";
import userService from "../../services/userServices";

import {
  updateUserRequest,
  updateUserSuccess,
  upateUserFailure,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserFailure,
  setShowUpdateUserForm,
} from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";

const country = [
  { id: 1, name: "India" },
  { id: 2, name: "United States" },
  { id: 3, name: "China" },
  { id: 4, name: "Indonesia" },
  { id: 5, name: "Pakistan" },
  { id: 6, name: "Brazil" },
  { id: 7, name: "Nigeria" },
  { id: 8, name: "Bangladesh" },
  { id: 9, name: "Russia" },
  { id: 10, name: "Mexico" },
  { id: 11, name: "Japan" },
  { id: 12, name: "Ethiopia" },
  { id: 13, name: "Philippines" },
  { id: 14, name: "Egypt" },
  { id: 15, name: "Vietnam" },
  { id: 16, name: "DR Congo" },
  { id: 17, name: "Turkey" },
  { id: 18, name: "Iran" },
  { id: 19, name: "Germany" },
  { id: 20, name: "Thailand" },
  { id: 21, name: "France" },
  { id: 22, name: "United Kingdom" },
  { id: 23, name: "Italy" },
  { id: 24, name: "South Africa" },
  { id: 25, name: "Tanzania" },
  { id: 26, name: "Myanmar" },
  { id: 27, name: "South Korea" },
  { id: 28, name: "Colombia" },
  { id: 29, name: "Kenya" },
  { id: 30, name: "Spain" },
];

const EditProfileForm = () => {
  const { currentUserInfo } = useSelector((state) => state.user);

  const initialState = {
    brandName: currentUserInfo?.brandName,
    country: currentUserInfo?.country,
    street: currentUserInfo?.street,
    city: currentUserInfo?.city,
    state: currentUserInfo?.state,
    postalCode: currentUserInfo?.postalCode,
  };

  const [formData, setFormData] = useState(initialState);

  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

  const getCurrentUserInfo = async () => {
    try {
      dispatch(getCurrentUserRequest());
      const response = await userService.getCurrentUserInfo();
      if (response.success === true) {
        dispatch(getCurrentUserSuccess(response.result));
      }
    } catch (err) {
      dispatch(getCurrentUserFailure(err.message));
    }
  };

  const updateProfile = async () => {
    dispatch(updateUserRequest());

    try {
      const response = await userService.updateUser(formData);
      if (response.success === true) {
        dispatch(updateUserSuccess(response.result));
        getCurrentUserInfo();
        dispatch(setShowUpdateUserForm(false));
      }
    } catch (err) {
      dispatch(upateUserFailure(err.message));
    }
  };

  const handleCountryChange = (country) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      country: country,
    }));
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));

    // validateField(e.target.name, e.target.value);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(setShowUpdateUserForm(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Edit profile
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="text-start flex flex-col">
                          {/* Brand Name */}
                          <div className="mb-3 sm:mb-3">
                            <label
                              for="brandName"
                              class="mb-2 text-sm text-start text-grey-900"
                            >
                              Brand Name
                            </label>
                            <input
                              autocomplete="off"
                              id="brandName"
                              type="string"
                              name="brandName"
                              value={formData.brandName}
                              onChange={(e) => handleChange(e)}
                              placeholder="Enter brand name"
                              style={{ backgroundColor: "#F6F8FD" }}
                              class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                            />
                          </div>

                          {/*  Country */}
                          <div className="sm:mb-3">
                            <label
                              for="country"
                              class="mb-2 text-sm text-start text-grey-900"
                            >
                              Country
                            </label>

                            <Dropdown
                              onChange={handleCountryChange}
                              items={country}
                            />
                          </div>

                          {/* street */}
                          <div className="flex flex-col mb-3 sm:mb-3">
                            <label
                              for="Street"
                              class="mb-2 text-sm text-start text-grey-900"
                            >
                              Street
                            </label>
                            <input
                              autocomplete="off"
                              id="street"
                              type="string"
                              name="street"
                              value={formData.street}
                              onChange={(e) => handleChange(e)}
                              placeholder="Enter Company Name"
                              style={{ backgroundColor: "#F6F8FD" }}
                              class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                            />
                          </div>

                          {/* city */}
                          <div className="mb-3 sm:mb-3">
                            <label
                              for="City"
                              class="mb-2 text-sm text-start text-grey-900"
                            >
                              City
                            </label>
                            <input
                              autocomplete="off"
                              id="city"
                              type="string"
                              name="city"
                              value={formData.city}
                              onChange={(e) => handleChange(e)}
                              placeholder="Enter city name"
                              style={{ backgroundColor: "#F6F8FD" }}
                              class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                            />
                            {/* {errors.customerId && (
                              <p className="text-xs flex items-start text-start text-red-500">
                                {errors.customerId}
                              </p>
                            )} */}
                          </div>

                          <div className="sm:flex  sm:flex-row gap-x-3">
                            {/* state */}

                            <div className="mb-3 sm:mb-3">
                              <label
                                for="state"
                                class="mb-2 text-sm text-start text-grey-900"
                              >
                                State
                              </label>
                              <input
                                autocomplete="off"
                                id="state"
                                type="name"
                                name="state"
                                value={formData.state}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter state name"
                                style={{ backgroundColor: "#F6F8FD" }}
                                class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                              />
                              {/* {errors.firstName && (
                                <p className="text-xs flex items-start text-start text-red-500">
                                  {errors.firstName}
                                </p>
                              )} */}
                            </div>

                            <div className="mb-3 sm:mb-3">
                              {/* postal code */}
                              <label
                                for="Postal code"
                                class="mb-2 text-sm text-start text-grey-900"
                              >
                                Postal code
                              </label>
                              <input
                                autocomplete="off"
                                id="postalCode"
                                type="number"
                                name="postalCode"
                                value={formData.postalCode}
                                onChange={(e) => handleChange(e)}
                                placeholder="Enter postal code here"
                                style={{ backgroundColor: "#F6F8FD" }}
                                class="flex items-center w-full px-5 py-2 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-lg"
                              />
                              {/* {errors.lastName && (
                                <p className="text-xs flex items-start text-start text-red-500">
                                  {errors.lastName}
                                </p>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 sm:ml-3 sm:w-auto"
                    onClick={updateProfile}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border hover:border-blue-600 sm:mt-0 sm:w-auto focus:ring-4 focus:ring-blue-100"
                    onClick={(e) => dispatch(setShowUpdateUserForm(false))}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditProfileForm;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  margin: 0;
  padding: 0;

  background-color: rgba(187, 187, 187, 0.137);
}
body {
  font-family: "Poppins", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;;EAEV,4CAA4C;AAC9C;AAGA;EACE,kCAAkC;AACpC","sourcesContent":[".App {\n  margin: 0;\n  padding: 0;\n\n  background-color: rgba(187, 187, 187, 0.137);\n}\n\n@import url(\"https://fonts.googleapis.com/css2?family=Poppins&display=swap\");\nbody {\n  font-family: \"Poppins\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

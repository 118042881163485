import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { dropDownReducer } from "./dropDownReducer";
import { paymentReducer } from "./paymentReducer";
import { customerReducer } from "./customerReducer";
import { userReducer } from "./userReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  customer: customerReducer,
  dropdowns: dropDownReducer,
  paymnets: paymentReducer,
  user: userReducer,
});

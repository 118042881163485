import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/assets";

const EmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificationResult, setVerificationResult] = useState({});

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/verify-email/${token}`);
        setVerificationResult(response.data);
        console.log("response.datauserata===", response.data.userData);
        if (
          response.data.success === true &&
          response.data.userData?.userStatus === "approved"
        ) {
          // If email verification is successful, store the token in localStorage
          localStorage.setItem("x-access-token", token);
          // Redirect to admin dashboard
          navigate("/admin/dashboard");
        } else {
          navigate("/signin");
        }
      } catch (error) {
        // console.error("Error verifying email:", error);
        setVerificationResult({
          success: false,
          message: "Error verifying email",
        });
      }
    };

    verifyEmail();
  }, [token, navigate]);

  return (
    <div>
      {verificationResult.success ? (
        <p>Email verification successful. {verificationResult.message}</p>
      ) : (
        <p>Email verification failed. {verificationResult.message}</p>
      )}
    </div>
  );
};

export default EmailVerification;

import {
  SET_COUNTRY_NAME,
  SET_SHOW_FORM,
  SET_SHOW_ADDBUYER_FORM,
  SET_SHOW_EXISTING_BUYER,
  SET_SHOW_PAYMENTLINK,
} from "../constants/Dropdown.contants";

export const setCountryName = (selectedCountry) => {
  return {
    type: SET_COUNTRY_NAME,
    payload: selectedCountry,
  };
};

export const setShowForm = (showForm) => {
  return {
    type: SET_SHOW_FORM,
    payload: showForm,
  };
};

export const setShowAddBuyerForm = (showAddBuyerForm) => {
  return {
    type: SET_SHOW_ADDBUYER_FORM,
    payload: showAddBuyerForm,
  };
};

export const setShowExistingBuyer = (showExistingBuyer) => {
  return {
    type: SET_SHOW_EXISTING_BUYER,
    payload: showExistingBuyer,
  };
};

export const setShowPaymentLink = (showPaymentLink) => {
  return {
    type: SET_SHOW_PAYMENTLINK,
    payload: showPaymentLink,
  };
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminDasboard_main {
    height: 100%;
    /* width: ; */
  }
  
  .card_list_cont {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ProfileManagement/profileManagemenet.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,SAAS;IACT,YAAY;EACd","sourcesContent":[".adminDasboard_main {\n    height: 100%;\n    /* width: ; */\n  }\n  \n  .card_list_cont {\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    gap: 1rem;\n    margin: 1rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

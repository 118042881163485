import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  SET_SHOW_UPDATEUSER_FORM,
} from "../constants/User.constants";

const initialState = {
  loading: false,
  upatedUserData: {},
  showUpdateUserForm: false,
  currentUserInfo: {},
  error: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        upatedUserData: action.payload,
        error: null,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUserInfo: action.payload,
        erorr: null,
      };

    case GET_CURRENT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_SHOW_UPDATEUSER_FORM:
      return {
        ...state,
        showUpdateUserForm: action.payload,
      };

    default:
      return state;
  }
};

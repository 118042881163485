import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  userLoginRequest,
  userLoginSuccess,
  userLoginFail,
} from "../../actions/authActions";

import authService from "../../services/authService";
import Dropdown from "../../components/Dropdown/Dropdown";
import {
  signinSchema,
  signupSchema,
  forgotPassSchema,
} from "../../utils/validationSchema";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const country = [
  { id: 1, name: "India" },
  { id: 2, name: "United States" },
  { id: 3, name: "China" },
  { id: 4, name: "Indonesia" },
  { id: 5, name: "Pakistan" },
  { id: 6, name: "Brazil" },
  { id: 7, name: "Nigeria" },
  { id: 8, name: "Bangladesh" },
  { id: 9, name: "Russia" },
  { id: 10, name: "Mexico" },
  { id: 11, name: "Japan" },
  { id: 12, name: "Ethiopia" },
  { id: 13, name: "Philippines" },
  { id: 14, name: "Egypt" },
  { id: 15, name: "Vietnam" },
  { id: 16, name: "DR Congo" },
  { id: 17, name: "Turkey" },
  { id: 18, name: "Iran" },
  { id: 19, name: "Germany" },
  { id: 20, name: "Thailand" },
  { id: 21, name: "France" },
  { id: 22, name: "United Kingdom" },
  { id: 23, name: "Italy" },
  { id: 24, name: "South Africa" },
  { id: 25, name: "Tanzania" },
  { id: 26, name: "Myanmar" },
  { id: 27, name: "South Korea" },
  { id: 28, name: "Colombia" },
  { id: 29, name: "Kenya" },
  { id: 30, name: "Spain" },
];

const SigninScreen = () => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    bussinessName: "",
    businessRegCountry: "India",
  };
  const dispatch = useDispatch();
  const { selectedCountry } = useSelector((state) => state.dropdowns);

  const [switchForm, setSwitchForm] = useState("login");
  const [forgPassEmail, setForgPassEmail] = useState("");
  const { loading, error, message, userData } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loginEmailPassError, setLoginEmailPassError] = useState("");
  const [signupEmailPassError, setSignupEmailPassError] = useState("");
  const [forgotPassEmailError, setForgotPassEmailError] = useState("");
  const [forgotPassEmailSuccess, setForgotPassEmailSuccess] = useState("");

  const [termsChecked, setTermsChecked] = useState(false); // State to track terms and conditions checkbox
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [TermsCheckBoxError, setTermsCheckBoxError] = useState("");
  const [privacyCheckBoxError, setPrivacyCheckBoxError] = useState("");

  // useEffect(() => {
  //   const token = localStorage.getItem("x-access-token");
  //   const userType = localStorage.getItem("userType");
  //   if (token) {
  //     switch (userType) {
  //       case "admin":
  //         navigate("/admin/dashboard");
  //     }
  //   }
  // }, []);

  const handleChange = (e) => {
    setLoginEmailPassError("");
    setSignupEmailPassError("");
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Validate the field based on the schema
    if (switchForm === "login") {
      validateField(name, value, signinSchema);
    } else if (switchForm === "signup") {
      validateField(name, value, signupSchema);
    }
  };

  const validateField = async (fieldName, value, schema) => {
    try {
      await schema.validateAt(fieldName, { [fieldName]: value });
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: undefined,
      }));
    } catch (err) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: err.message,
      }));
    }
  };

  const handleChangeForgPassEmail = (e) => {
    setForgotPassEmailError("");
    setForgotPassEmailSuccess("");
    const { name, value } = e.target;

    setForgPassEmail(e.target.value);
    validateField(name, value, forgotPassSchema);
  };

  const handleCountryChange = (country) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      businessRegCountry: country,
    }));
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!termsChecked) {
      // toast.error("Please check the terms and conditions.");
      setTermsCheckBoxError("Please check the terms and conditions.");
      return;
    }
    if (!privacyChecked) {
      setPrivacyCheckBoxError("Please check the privacy policy");
      return;
    }

    try {
      await signupSchema.validate(formData, { abortEarly: false });
      const response = await authService.signupUser(formData);
      if (response.success === true) {
        setSwitchForm("login");
      } else if (response.success === false) {
        // toast.error(response.message);
        setSignupEmailPassError(response.message);
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      } else {
      }
    }
  };

  const handleSignin = async (e) => {
    e.preventDefault();
    try {
      await signinSchema.validate(formData, { abortEarly: false });

      dispatch(userLoginRequest());

      const response = await authService.signinUSer(formData);
      if (
        response.success === true &&
        response.userData?.userStatus === "approved"
      ) {
        dispatch(userLoginSuccess(response));
        localStorage.setItem("x-access-token", response.token);
        navigate("/admin/dashboard");
      } else if (
        response.success === true &&
        response.userData?.userStatus === "pending"
      ) {
        setLoginEmailPassError(
          "Your account is not approved yet, Please wait for admin approval."
        );
      } else if (
        response.success === true &&
        response.userData?.userStatus === "rejected"
      ) {
        setLoginEmailPassError(
          "Your profile was rejected by the admin. Please Contact admin@fastpagos."
        );
      } else if (response.success === false) {
        setLoginEmailPassError(response.message);
        // toast.error(response.message);
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      } else {
        dispatch(userLoginFail(err.message));
      }
    }
  };

  const handleForgotPass = async (e) => {
    e.preventDefault();
    try {
      const response = await authService.ForgotPassword(forgPassEmail);
      // console.log("response L194===", response);
      if (response.success === true) {
        setForgotPassEmailSuccess(response.message);
        // toast.success(response.message);
        return response;
      } else if (response.success === false) {
        setForgotPassEmailError(response.message);
        // toast.error(response.message);
      }
    } catch (err) {
      // console.log("messae==", err.message.message);
      toast.error(err.message.mesage);
    }
  };

  const toggleTermsCheckBox = () => {
    setTermsCheckBoxError("");
    setTermsChecked((prevChecked) => !prevChecked);
  };

  const togglePrivacyCheckBox = () => {
    setPrivacyCheckBoxError("");
    setPrivacyChecked((prevChecked) => !prevChecked);
  };

  return (
    //signin ui

    <div class="flex bg-white  justify-center items-center justify-center w-full  h-screen bg-red-200 md:h-screen sm:h-screen my-auto xl:gap-14 lg:justify-normal md:gap-5 draggable">
      {switchForm === "login" ? (
        <div
          style={{
            backgroundImage: `url('/images/3383108.jpg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          class="flex  h-full  items-center justify-center w-full lg:p-12"
        >
          <div class="flex rounded-lg  justify-center items-center bg-white p-5 xl:p-10">
            <form class="flex flex-col w-full h-full pb-6 text-center bg-white rounded-3xl">
              <h1 class="text-4xl p-1 font-bold text-center text-transparent bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text mb-8">
                fastpagos
              </h1>
              <div className="mb-5">
                {/* email field */}
                <label
                  for="email"
                  class="mb-2 text-sm flex items-start  text-start text-grey-900"
                >
                  Email*
                </label>
                <input
                  autocomplete="off"
                  id="email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => handleChange(e)}
                  placeholder="mail@loopple.com"
                  style={{ backgroundColor: "#F6F8FD" }}
                  class={`${
                    loginEmailPassError !== "" ? "border border-red-500" : ""
                  } flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-2xl`}
                />
                {errors.email && (
                  <p className=" text-xs flex items-start text-start text-red-500">
                    {errors.email}
                  </p>
                )}
              </div>

              <div className="mb-5">
                {/* password field */}
                <label
                  for="password"
                  class="mb-2 text-sm flex items-start  text-start text-grey-900"
                >
                  Password*
                </label>
                <input
                  autocomplete="off"
                  id="password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter a password"
                  style={{ backgroundColor: "#F6F8FD" }}
                  class={` ${
                    loginEmailPassError !== "" ? "border border-red-500" : ""
                  } flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-2xl`}
                />
                {errors.password && (
                  <p className=" text-xs flex items-start text-start text-red-500">
                    {errors.password}
                  </p>
                )}

                <p className=" text-xs flex items-start text-start text-red-500">
                  {loginEmailPassError}
                </p>
              </div>
              {/* Display password validation error */}
              <div class="flex flex-row justify-between mb-6">
                <a
                  onClick={() => setSwitchForm("forgot-password")}
                  href="javascript:void(0)"
                  class="mr-4 text-sm font-medium text-blue-500"
                >
                  forgot password?
                </a>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSignin(e);
                }}
                class="w-full px-6 py-5 mb-4 text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 bg-blue-500"
              >
                Sign In
              </button>
              <ToastContainer />
              <p class="text-sm leading-relaxed text-grey-900">
                Not registered yet?{" "}
                <a
                  onClick={() => {
                    setSwitchForm("signup");
                  }}
                  href="javascript:void(0)"
                  class="font-medium text-blue-500"
                >
                  <br />
                  Create an Account
                </a>
              </p>
            </form>
          </div>
        </div>
      ) : switchForm === "signup" ? (
        <div
          style={{
            backgroundImage: `url('/images/3383108.jpg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          class="flex  h-full  items-center justify-center w-full p-5 lg:p-12"
        >
          <div class="flex w-full sm:w-auto sm:h-auto justify-center items-center bg-white  sm:p-0 rounded-lg  ">
            <form class="flex flex-col  sm:p-5  w-full h-full sm:h-auto pb-6 text-center xl:px-10 xl:py-4  rounded-3xl">
              <h1 class="text-4xl p-1 font-bold text-center text-transparent bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text mb-5">
                fastpagos
              </h1>

              <div className="text-start flex flex-col">
                <div className="sm:flex  sm:flex-row gap-x-3">
                  {/* first Name */}

                  <div className="mb-3">
                    <label
                      for="first name"
                      class="mb-2 text-sm text-start text-grey-900"
                    >
                      First Name*
                    </label>
                    <input
                      autocomplete="off"
                      id="firstName"
                      type="name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={(e) => handleChange(e)}
                      placeholder="John"
                      style={{ backgroundColor: "#F6F8FD" }}
                      class="flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-100   placeholder:text-grey-700 text-dark-grey-900 rounded-2xl"
                    />
                    {errors.firstName && (
                      <p className=" text-xs flex items-start text-start text-red-500">
                        {errors.firstName}
                      </p>
                    )}
                  </div>

                  <div className="mb-3">
                    {/* last Name */}
                    <label
                      for="last name"
                      class="mb-2 text-sm text-start text-grey-900"
                    >
                      Last Name*
                    </label>
                    <input
                      autocomplete="off"
                      id="lastName"
                      type="name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={(e) => handleChange(e)}
                      placeholder="levon"
                      style={{ backgroundColor: "#F6F8FD" }}
                      class="flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-2xl"
                    />
                    {errors.lastName && (
                      <p className=" text-xs flex items-start text-start text-red-500">
                        {errors.lastName}
                      </p>
                    )}
                  </div>
                </div>

                <div className="sm:flex sm:flex-row gap-x-3">
                  <div className="mb-3">
                    {/* email */}
                    <label
                      for="email"
                      class="mb-2 text-sm text-start text-grey-900"
                    >
                      Email*
                    </label>
                    <input
                      autocomplete="off"
                      id="email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={(e) => handleChange(e)}
                      placeholder="mail@loopple.com"
                      style={{ backgroundColor: "#F6F8FD" }}
                      class={`${
                        signupEmailPassError !== ""
                          ? "border border-red-500"
                          : ""
                      }  flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-2xl`}
                    />
                    {errors.email && (
                      <p className=" text-xs flex items-start text-start text-red-500">
                        {errors.email}
                      </p>
                    )}
                    <p className=" text-xs flex items-start text-start text-red-500">
                      {signupEmailPassError}
                    </p>
                  </div>

                  {/* password */}
                  <div className="mb-3">
                    <label
                      for="password"
                      class="mb-2 text-sm text-start text-grey-900"
                    >
                      Password*
                    </label>
                    <input
                      autocomplete="off"
                      id="password"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter a password"
                      style={{ backgroundColor: "#F6F8FD" }}
                      class="flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-100 placeholder:text-grey-700 text-dark-grey-900 rounded-2xl"
                    />
                    {errors.password && (
                      <p className=" text-xs flex items-start text-start text-red-500">
                        {errors.password}
                      </p>
                    )}
                  </div>
                </div>

                <div className="sm:flex sm:flex-row gap-x-3">
                  {/* Business name */}
                  <div className="mb-3">
                    <label
                      for="bussinessName"
                      class="mb-2 text-sm text-start text-grey-900"
                    >
                      Business Name*
                    </label>
                    <input
                      autocomplete="off"
                      id="bussinessName"
                      type="string"
                      name="bussinessName"
                      value={formData.bussinessName}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter Business Name"
                      style={{ backgroundColor: "#F6F8FD" }}
                      class="flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-2xl"
                    />
                    {errors.bussinessName && (
                      <p className=" text-xs flex items-start text-start text-red-500">
                        {errors.bussinessName}
                      </p>
                    )}
                  </div>
                  {/* Business Registration Country */}
                  <div>
                    <label
                      for="businessRegCountry"
                      class="mb-2 text-sm text-start text-grey-900"
                    >
                      Business Registration Country*
                    </label>

                    <Dropdown onChange={handleCountryChange} items={country} />
                  </div>
                </div>
              </div>

              <div className="flex mb-3 flex-col justify-start items-start">
                <div className="flex flex-row justify-start items-center">
                  <input
                    type="checkbox"
                    checked={termsChecked}
                    onClick={toggleTermsCheckBox}
                    className="border mr-2 hover:cursor-pointer"
                  />

                  <div className="flex text-sm flex-row gap-2">
                    <span htmlFor="terms">I agree to the</span>
                    <Link
                      to={`/terms`}
                      target="_blank" // Add target="_blank" to open link in new tab
                      rel="noopener noreferrer" // Add rel="noopener noreferrer" for security
                      className=" hover:cursor-pointer hover:underline text-blue-500"
                    >
                      Terms & conditions
                    </Link>
                  </div>
                </div>
                <p className=" text-xs flex items-start text-start text-red-500">
                  {TermsCheckBoxError}
                </p>
              </div>

              <div className="flex mb-3 flex-col justify-start items-start">
                <div className="flex flex-row justify-start items-center">
                  <input
                    type="checkbox"
                    checked={privacyChecked}
                    onClick={togglePrivacyCheckBox}
                    className="mr-2 hover:cursor-pointer"
                  />
                  <div className="flex text-sm flex-row gap-2">
                    <span htmlFor="terms">I agree to the</span>
                    <Link
                      to="/policy"
                      target="_blank" // Add target="_blank" to open link in new tab
                      rel="noopener noreferrer" // Add rel="noopener noreferrer" for security
                      className=" hover:cursor-pointer hover:underline  text-blue-500"
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </div>
                <p className=" text-xs flex items-start text-start text-red-500">
                  {privacyCheckBoxError}
                </p>
              </div>

              <div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSignup(e);
                  }}
                  class="w-full px-6 py-5 mb-2 text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 bg-blue-500"
                >
                  Sign Up
                </button>
                <ToastContainer />
              </div>

              <p class="text-sm leading-relaxed text-grey-900">
                Already have an account?{" "}
                <a
                  onClick={() => setSwitchForm("login")}
                  href="javascript:void(0)"
                  class="font-medium text-blue-500"
                >
                  <br />
                  signin
                </a>
              </p>
            </form>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundImage: `url('/images/3383108.jpg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          class="flex  h-full  items-center justify-center w-full lg:p-12"
        >
          <div class="flex rounded-lg  justify-center items-center bg-white p-5 xl:p-10">
            <form class="flex flex-col w-full h-full pb-6 text-center bg-white rounded-3xl">
              <h1 class="text-4xl p-1 font-bold text-center text-transparent bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text mb-5">
                fastpagos
              </h1>
              <div className="mb-7 ">
                <label
                  for="email"
                  class="mb-2 text-sm flex items-start justify-start text-start text-grey-900"
                >
                  Email*
                </label>
                <input
                  autocomplete="off"
                  id="email"
                  type="email"
                  name="email"
                  value={forgPassEmail}
                  onChange={(e) => handleChangeForgPassEmail(e)}
                  placeholder="mail@loopple.com"
                  style={{ backgroundColor: "#F6F8FD" }}
                  class={`${
                    forgotPassEmailError !== "" ? "border border-red-500" : ""
                  }  flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-2xl`}
                />
                {errors.email && (
                  <p className=" text-xs  flex items-start text-start text-red-500">
                    {errors.email}
                  </p>
                )}
                <p className=" text-xs  flex items-start text-start text-red-500">
                  {forgotPassEmailError}
                </p>
                <p className=" text-xs  flex items-start text-start text-green-500">
                  {forgotPassEmailSuccess}
                </p>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleForgotPass(e);
                }}
                class="w-full px-6 py-5 mb-5 text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 bg-blue-500"
              >
                Send
              </button>
              <ToastContainer />

              <p class="text-sm leading-relaxed text-grey-900">
                Already have an account?{" "}
                <a
                  onClick={() => setSwitchForm("login")}
                  href="javascript:void(0)"
                  class="font-bold text-grey-700"
                >
                  signin
                </a>
              </p>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SigninScreen;

export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILURE = "CREATE_CUSTOMER_FAILURE";

export const GET_ALL_CUSTOMER_BYUSERID_REQUEST =
  "GET_ALL_CUSTOMER_BYUSERID_REQUEST";
export const GET_ALL_CUSTOMER_BYUSERID_SUCCESS =
  "GET_ALL_CUSTOMER_BYUSERID_SUCCESS";
export const GET_ALL_CUSTOMER_BYUSERID_FAILURE =
  "GET_ALL_CUSTOMER_BYUSERID_FAILURE";

export const SET_SELECTED_BUYER = "SET_SELECTED_BUYER";

import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  SET_SHOW_UPDATEUSER_FORM,
} from "../constants/User.constants";

export const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};

export const updateUserSuccess = (newUser) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: newUser,
  };
};

export const upateUserFailure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: error,
  };
};

export const getCurrentUserRequest = () => {
  return {
    type: GET_CURRENT_USER_REQUEST,
  };
};

export const getCurrentUserSuccess = (currentUser) => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    payload: currentUser,
  };
};

export const getCurrentUserFailure = (error) => {
  return {
    type: GET_CURRENT_USER_FAILURE,
    payload: error,
  };
};

export const setShowUpdateUserForm = (showUpdateUserForm) => {
  return {
    type: SET_SHOW_UPDATEUSER_FORM,
    payload: showUpdateUserForm,
  };
};

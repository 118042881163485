import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import { resetPasswordSchema } from "../../utils/validationSchema"; // Assuming you have imported the validation schema

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { token } = useParams();

  // Define validation schema using Yup

  const handleChange = async (e) => {
    const { value } = e.target;
    setNewPassword(value);
    try {
      await resetPasswordSchema.validate({ password: value });
      setError("");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleUpdatePassword = async () => {
    try {
      await resetPasswordSchema.validate({ password: newPassword });
      const response = await authService.resetPassword(token, newPassword);
      if (response.success === true) {
        navigate("/signin");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex bg-white justify-center items-center justify-center w-full h-screen sm:h-full my-auto xl:gap-14 lg:justify-normal md:gap-5 draggable">
      <div className="flex bg-white items-center justify-center w-full lg:p-12">
        <div className="flex justify-center items-start bg-white xl:p-10">
          <form className="flex flex-col w-full h-full pb-6 text-start bg-white rounded-3xl">
            <h1 className="text-4xl p-1 font-bold text-center text-transparent bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text mb-8">
              fastpagos
            </h1>
            <div className="mb-4">
              <label
                htmlFor="newPassword"
                className="mb-2 text-sm text-start text-grey-900"
              >
                Password*
              </label>
              <input
                id="newPassword"
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                placeholder="Enter your new password"
                style={{ backgroundColor: "#F6F8FD" }}
                className="flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-100  placeholder:text-grey-700 text-dark-grey-900 rounded-2xl"
              />
              {error && (
                <div className="text-xs flex items-start text-start text-red-500">
                  {error}
                </div>
              )}
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleUpdatePassword();
              }}
              className="w-full px-6 py-5 mb-5 text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 bg-blue-500"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

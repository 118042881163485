import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  SortingState,
  ColumnFiltersState,
  getFilteredRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal } from "lucide-react";
import { debounce } from "lodash";
import { useMemo, useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import paymentService from "../../services/paymentService";
import {
  getAllPaymentsRequest,
  getAllPaymentsSuccess,
  getAllPaymentsFailure,
} from "../../actions/paymentActions";
import { CiMenuKebab } from "react-icons/ci";
import { toast, ToastContainer } from "react-toastify";
import { Input } from "../Input/Input";

const TransactionTable = () => {
  const { AllPayments, newPayment } = useSelector((state) => state.paymnets);
  // console.log("allpayments===", AllPayments);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const menuRef = useRef(null);

  const dispatch = useDispatch();

  const fetchPaymentData = async () => {
    try {
      dispatch(getAllPaymentsRequest());

      const response = await paymentService.getAllPaymentsByUserId();
      // console.log("responnse 111===", response);
      if (response.success === true) {
        dispatch(getAllPaymentsSuccess(response.data));
      }
    } catch (err) {
      dispatch(getAllPaymentsFailure(err.message));
    }
  };

  const handleSendEmailToSelf = async (tableInfo) => {
    const emailData = {
      userId: tableInfo?.userId?._id,
      customerDetailId: tableInfo?.customerId?._id,
      invoiceId  : tableInfo?._id,
      currency: tableInfo?.currency,
      amount: tableInfo?.amount,
      description: tableInfo?.description,
      paymentId: tableInfo?.paymentId,
      paymentLink: tableInfo?.paymentLink,
      status: tableInfo?.status,
      dueDate: moment(tableInfo?.createdAt).format("DD MMM YYYY"),
    };
    setOpenMenuId(null);
    toast.success("Email sent");

    const response = await paymentService.pdfSender(emailData);
    return response;
  };

  
  const handleDownloadInvoice = async (tableInfo) => {
    try{
      const emailData = {
        customerId: tableInfo?.customerId?._id,
        userDetailId: tableInfo?.userId?._id,
        invoiceId  : tableInfo?._id,
        currency: tableInfo?.currency,
        amount: tableInfo?.amount,
        description: tableInfo?.description,
        paymentId: tableInfo?.paymentId,
        paymentLink: tableInfo?.paymentLink,
        status: tableInfo?.status,
        dueDate: moment(tableInfo?.createdAt).format("DD MMM YYYY"),
      };
      setOpenMenuId(null);
      
      const response = await paymentService.invoiceDownload(emailData);    
      console.log("got response");

      const url = window.URL.createObjectURL(new Blob([response]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Invoice1.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();
      
      toast.success("Invoice Downloaded");

      return response;
    }catch (err) {
      console.error("Unable to download invoice", err);
      toast.error("Couldn't download Invoice");      
    }
  };

  const handleSendEmailToBuyer = async (tableInfo) => {
    const emailData = {
      customerId: tableInfo?.customerId?._id,
      userDetailId: tableInfo?.userId?._id,
      invoiceId  : tableInfo?._id,
      currency: tableInfo?.currency,
      amount: tableInfo?.amount,
      description: tableInfo?.description,
      paymentId: tableInfo?.paymentId,
      paymentLink: tableInfo?.paymentLink,
      status: tableInfo?.status,
      dueDate: moment(tableInfo?.createdAt).format("DD MMM YYYY"),
    };
    setOpenMenuId(null);
    toast.success("Email sent");

    const response = await paymentService.pdfSender(emailData);
    return response;
  };

  useEffect(() => {
    fetchPaymentData();
  }, [newPayment]);

  useEffect(() => {
    // Function to handle clicks outside of the menu
    const handleClickOutside = (event) => {
      // Check if the clicked element is inside the menu or the menu trigger
      if (
        event.target.closest(".menu-trigger") ||
        event.target.closest(".menu-dialog")
      ) {
        // Click occurred inside the menu or the menu trigger, do nothing
        return;
      }

      // Click occurred outside of the menu, close the menu
      setOpenMenuId(null);
    };

    // Function to handle clicks inside the component but not in the dialog
    const handleClickInside = (event) => {
      // Check if the clicked element is inside the component but not in the dialog
      if (
        menuRef.current &&
        !event.target.closest(".menu-trigger") &&
        !event.target.closest(".menu-dialog") &&
        menuRef.current.contains(event.target)
      ) {
        // Click occurred inside the component but not in the dialog, close the menu
        setOpenMenuId(null);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("click", handleClickInside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("click", handleClickInside);
    };
  }, []);

  const handleMenuClick = (id) => {
    setOpenMenuId((prevId) => (prevId === id ? null : id));
  };

  const handleCopyLink = (tableInfo) => {
    // navigator.clipboard.writeText(tableInfo.paymentLink);
    const textArea = document.createElement("textarea");
    textArea.value = tableInfo.paymentLink;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      toast.success("Link copied");
      setOpenMenuId(null);
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
      toast.error("Link not copied");
      setOpenMenuId(null);
    }
    document.body.removeChild(textArea);
  };

  // const data = useMemo(() => AllPayments, []);
  /**@type import('@tanstack/react-table').columnDef<any> */

  const columns = [
    {
      header: "TRANSACTION NO",
      accessorKey: "_id",
      cell: (info) => {
        return (
          <div className="text-start text-black">
            {info?.row?.original?._id || "N/A"}
          </div>
        );
      },
    },

    {
      header: "STATUS",
      accessorKey: "status",
      cell: (info) => {
        return (
          <div
            className={`${
              info?.row?.original?.status === "Success"
                ? "text-green-700 bg-green-100"
                : ""
            } ${
              info?.row?.original?.status === "Pending"
                ? "text-yellow-700 bg-yellow-100"
                : ""
            } text-center rounded-lg sm:py- text-black`}
          >
            {info?.row?.original?.status || "N/A"}
          </div>
        );
      },
    },
    {
      header: ({ column }) => {
        return (
          <div className="flex flex-row justify-end">
            <button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              AMOUNT
            </button>
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </div>
        );
      },
      accessorKey: "amount",
      cell: (info) => {
        return (
          <div className="flex justify-end flex-row gap-1">
            <div className="text-end text-black">
              {info.row.original.amount || "N/A"}
            </div>
            <div className="text-end text-gray-400 text-black">
              {info?.row?.original?.currency?.toUpperCase() || ""}
            </div>
          </div>
        );
      },
    },
    {
      header: "BUYER",
      accessorKey: "customerId.email",
      cell: (info) => {
        return (
          <div className="text-start text-black">
            {info?.row?.original?.customerId?.email || "N/A"}
          </div>
        );
      },
    },
    {
      header: "DATE",
      accessorKey: "createdAt",
      cell: (info) => {
        return (
          <div className="text-start">
            {moment(info?.row?.original?.createdAt).format(
              "DD MMM YYYY, HH:mm"
            ) || "N/A"}
          </div>
        );
      },
    },

    {
      header: "OPTIONS",
      cell: (info) => (
        <div ref={menuRef} className="text-center">
          <div
            className="cursor-pointer flex justify-center items-center menu-trigger "
            onClick={() => handleMenuClick(info?.row?.original?._id)}
          >
            <CiMenuKebab />
          </div>

          {openMenuId === info?.row?.original?._id && (
            <div className="menu-dialog origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div
                  onClick={() => handleSendEmailToSelf(info?.row?.original)}
                  className="block hover:cursor-pointer py-2 px-3 text-xs text-start text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Resend email to self
                </div>

                <div
                  onClick={() => handleSendEmailToBuyer(info?.row?.original)}
                  className="block hover:cursor-pointer  py-2 px-3 text-xs text-start text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Resend email to buyer
                </div>

                <div
                  onClick={() => handleCopyLink(info?.row?.original)}
                  className="block hover:cursor-pointer  py-2 px-3 text-xs text-start text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Copy payment link
                </div>

                <div
                  onClick={() => handleDownloadInvoice(info?.row?.original)}
                  className="block hover:cursor-pointer  py-2 px-3 text-xs text-start text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Download Invoice
                </div>

              </div>
            </div>
          )}
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data: AllPayments,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <div>
        <div className="flex w-80 items-center py-4">
          <Input
            placeholder="Search by status..."
            value={(
              table.getColumn("status")?.getFilterValue() || ""
            ).toString()}
            onChange={(event) => {
              const value = event.target.value;
              table.getColumn("status")?.setFilterValue(value);
            }}
          />
        </div>
      </div>

      <table className="w-full ">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup._id} className="">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={` ${
                    header.column.columnDef.header === "TRANSACTION NO"
                      ? "text-start"
                      : ""
                  } ${
                    header.column.columnDef.header === "AMOUNT"
                      ? "text-end"
                      : ""
                  }  
                  ${
                    header.column.columnDef.header === "BUYER"
                      ? "text-start"
                      : ""
                  } 

                  ${
                    header.column.columnDef.header === "DATE"
                      ? "text-start"
                      : ""
                  } 


                  text-[0.7rem] text-gray-700  px-4 py-2`}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row._id} className=" border-b">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell._id}
                  className=" text-[0.7rem] font-medium text-center border-gray-400 px-4 py-2"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};

export default TransactionTable;

//   {
//     "userId": "65ceffad5c2ef2530489adec",
//     "customerId": "65d2424ac7a4ca987c78e612",
//     "currency": "",
//     "amount": 4999,
//     "description": "hghbv",
//     "paymentId": "plink_1Oo1ycSEnW4RGvbMBG0BM1S9",
//     "paymentLink": "https://buy.stripe.com/test_dR6cPZbNr1eGf9C7th",
//     "status": "Pending",
//     "_id": "65dc7027b5112bc8968a9118",
//     "createdAt": "2024-02-26T11:04:07.107Z",
//     "updatedAt": "2024-02-26T11:04:07.108Z",
//     "__v": 0
// }
// ============================================================
//   {
//     "_id": "65db8e7b7ade220cfc135e20",
//     "userId": {
//         "_id": "65ceffad5c2ef2530489adec",
//         "firstName": "kapil",
//         "email": "nikhilsolanki0712@gmail.com",
//         "password": "$2a$11$A8nhbjsxiGw3Kh2t3zzTtOfE3MqOusqheRc5RpB/OV7XuRebkbFfe",
//         "userType": "customer",
//         "userStatus": "pending",
//         "createdAt": "2024-02-16T06:24:45.164Z",
//         "updatedAt": "2024-02-16T06:24:45.164Z",
//         "__v": 0,
//         "customerId": [
//             "65d08b20f2df2363a28a7ae7",
//
//         ]
//     },
//     "customerId": {
//         "_id": "65d08b20f2df2363a28a7ae7",
//         "userId": "65ceffad5c2ef2530489adec",
//         "transactionId": [
//             "65d2543cf48c09f314b33679",
//             "65d299ae6b944c64c8e0f149",
//            ],
//         "entityType": "Bussiness",
//         "country": "China",
//         "partnerCustomerId": "688223235",
//         "firstName": "rolex",
//         "lastName": "rolex",
//         "email": "nikhilsolanki0712@gmail.com",
//         "contactNumber": 9399160227,
//         "createdAt": "2024-02-17T10:32:00.202Z",
//         "updatedAt": "2024-02-17T10:32:00.202Z",
//         "__v": 0
//     },
//     "currency": "",
//     "amount": 5000,
//     "description": "five thousand",
//     "paymentId": "plink_1OnmwpSEnW4RGvbMbDLP9TdN",
//     "paymentLink": "https://buy.stripe.com/test_28obLV6t7aPg5z228V",
//     "status": "Success",
//     "createdAt": "2024-02-25T19:01:15.939Z",
//     "updatedAt": "2024-02-25T19:01:15.940Z",
//     "__v": 0
// }

import {
  GET_ALLPAYMENTS_FAILURE,
  GET_ALLPAYMENTS_REQUEST,
  GET_ALLPAYMENTS_SUCCESS,
  CREATE_PAYMENTS_REQUEST,
  CREATE_PAYMENTS_SUCCESS,
  CREATE_PAYMENTS_FAILURE,
} from "../constants/Payments.constants";

const initialState = {
  loading: false,
  AllPayments: [],
  newPayment: {},
  error: null,
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLPAYMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALLPAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        AllPayments: action.payload,
        error: null,
      };
    case GET_ALLPAYMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        AllPayments: [],
        error: action.payload,
      };

    case CREATE_PAYMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        newPayment: action.payload,
        error: null,
      };

    case CREATE_PAYMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        newPayment: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

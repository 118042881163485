import React from "react";
import { useSelector } from "react-redux";

const DispayBuyerData = () => {
  const { selectedBuyer } = useSelector((state) => state.customer);

  return (
    <div   className=" flex bg-white  sm:flex-row sm:items-start sm:justify-start border-gray-300 p-4 rounded-md">
      <div  className=" bg-white w-3/12 flex flex-col">
        <div className="mb-2">
          <span className="font-semibold text-gray-600 text-[0.8rem]">
            Entity Type :
          </span>
          {/* <span className="ml-2 text-[0.8rem]">{selectedBuyer.entityType}</span> */}
        </div>
        <div className="mb-2">
          <span className="font-semibold text-gray-600  text-[0.8rem]">
            Country :
          </span>
          {/* <span className="ml-2 text-[0.8rem]">{selectedBuyer.country}</span> */}
        </div>
        <div className="mb-2">
          <span className="font-semibold text-gray-600  text-[0.8rem]">
            Partner Customer ID :
          </span>
          {/* <span className="ml-2 text-[0.8rem]">
          {selectedBuyer.partnerCustomerId}
        </span> */}
        </div>
        <div className="mb-2">
          <span className="font-semibold text-gray-600  text-[0.8rem]">
            Name :
          </span>
          {/* <span className="ml-2 text-[0.8rem]">{`${selectedBuyer.firstName} ${selectedBuyer.lastName}`}</span> */}
        </div>
        <div className="mb-2">
          <span className="font-semibold text-gray-600  text-[0.8rem]">
            Email :
          </span>
          {/* <span className="ml-2 text-[0.8rem]">{selectedBuyer.email}</span> */}
        </div>
        <div>
          <span className="font-semibold text-gray-600  text-[0.8rem]">
            Contact Number :
          </span>
          {/* <span className="ml-2 text-[0.8rem]">
          {selectedBuyer.contactNumber}
        </span> */}
        </div>
      </div>
      <div className="flex flex-col">
        {/* values */}
        <div className="mb-2">
          <span className="ml-2 font-medium text-[0.8rem]">{selectedBuyer.entityType}</span>
        </div>
        <div className="mb-2">
          <span className="ml-2 text-[0.8rem]">{selectedBuyer.country}</span>
        </div>
        <div className="mb-2">
          <span className="ml-2 text-[0.8rem]">
            {selectedBuyer.partnerCustomerId}
          </span>
        </div>
        <div className="mb-2">
          <span className="ml-2 text-[0.8rem]">{`${selectedBuyer.firstName} ${selectedBuyer.lastName}`}</span>
        </div>
        <div className="mb-2">
          <span className="ml-2 text-[0.8rem]">{selectedBuyer.email}</span>
        </div>
        <div>
          <span className="ml-2 text-[0.8rem]">
            {selectedBuyer.contactNumber}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DispayBuyerData;

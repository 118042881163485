import {
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  GET_ALL_CUSTOMER_BYUSERID_REQUEST,
  GET_ALL_CUSTOMER_BYUSERID_SUCCESS,
  GET_ALL_CUSTOMER_BYUSERID_FAILURE,
  SET_SELECTED_BUYER,
} from "../constants/Customer.constants";

const initialState = {
  loading: false,
  allCustomer: [],
  newCustomer: {},
  selectedBuyer: {},
  error: null,
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CUSTOMER_BYUSERID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CUSTOMER_BYUSERID_SUCCESS:
      return {
        ...state,
        loading: false,
        allCustomer: action.payload,
        error: null,
      };
    case GET_ALL_CUSTOMER_BYUSERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        newCustomer: action.payload,
        error: null,
      };

    case CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        newCustomer: {},
        error: action.payload,
      };

    case SET_SELECTED_BUYER:
      return {
        ...state,
        selectedBuyer: action.payload,
      };

    default:
      return state;
  }
};

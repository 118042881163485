import React from "react";

const TermsScreen = () => {
  return (
    <div className=" w-full bg-white flex justify-center flex-col items-center">
      {/* page 1 */}
      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <h1 className="font-bold text-[2rem] ">Terms</h1>
        <p className="mt-4 text-sm text-wrap">
          This saas service agreement (the “Agreement”), dated effective as of
          the date Customer submits this form by clicking the “Create Account”
          box provided through this website, which click you acknowledge
          constitutes a signature (the “Effective Date”) is by and between
          FastPagos Inc, a Delaware limited liability company (“FastPagos”), and
          the customer identified in the signature block below (“Customer”).
          FastPagos has developed certain Software, as defined below, which
          Customer desires to use for its business purposes. Customer will be
          provided access to and use of the Software as part of the web-based
          FastPagos Service. In consideration of the mutual covenants and
          agreements contained herein, the sufficiency of which is hereby
          acknowledged, Customer and FastPagos agree as follows:
        </p>
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
            1. DEFINITIONS
          </h1>
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                1.1 “Account” means each individual Customer account in the
                FastPagos Service system that is associated with Customer’s
                subscription.
              </li>
              <li className="mb-4 text-wrap">
                1.2 “Activation Date” means the later of the date this Agreement
                is executed by Customer and accepted by FastPagos.
              </li>
              <li className="mb-4 text-wrap">
                1.3 “Administrative User” means Customer’s primary contact
                person with FastPagos, who has full access to the FastPagos
                Service and has the right and ability to control access for all
                other Authorized Users (i.e., can grant or restrict FastPagos
                Service access for other Authorized Users).
              </li>
              <li className="mb-4 text-wrap">
                1.4 “Authorized User(s)” means any person that Customer allows
                to have access to the FastPagos Service and Customer’s account,
                including Customer’s employees and staff and any third parties
                that Customer allows to view or use the FastPagos Service.
              </li>
              <li className="mb-4 text-wrap">
                1.5 “Billing Cycle” means each calendar month.
              </li>
              <li className="mb-4 text-wrap">
                1.6 “Confidential Information” means all proprietary and
                confidential information exchanged by the parties or to which
                access is provided by one party to the other, including the
                Software; trade secrets; the substantive terms of this
                Agreement; a party’s non-public business, strategic and
                financial information; any plans, programs or forecasts;
                intellectual property; Customer Data; Third-Party Software and
                other third-party confidential information that is disclosed by
                one party to the other, any written materials marked as
                confidential and any other information, including visual or oral
                information, which reasonably should be understood to be
                confidential. Confidential Information does not include
                information that the receiving party can prove: (a) is now or
                later becomes generally available to the public without fault of
                the receiving party; (b) was rightfully in the receiving party’s
                possession prior to its disclosure by the disclosing party; (c)
                is independently developed by the receiving party without the
                use of any Confidential Information of the disclosing party; or
                (d) is obtained by the receiving party without obligation of
                confidentiality from a third party who has the right to disclose
                it. The receiving party may also disclose Confidential
                Information to the extent required under a judicial or
                legislative order or proceeding; provided that the receiving
                party gives the disclosing party, if feasible, prior notice and
                an opportunity to respond or object to such disclosure.
              </li>
              <li className="mb-4 text-wrap">
                1.7 “Customer Data” means all information and data input by
                Customer or its Authorized Users into the FastPagos Service,
                including all usernames, passwords, and other data provided by
                Customer and its Borrowers. However, Customer Data does not
                include the Usage Data relating to Customer’s and its Users’ use
                of the FastPagos Service.
              </li>
              <li className="mb-4 text-wrap">
                1.8 “FastPagos Service” means a web-based, hosted SaaS service.
                The FastPagos Service is hosted on servers of FastPagos or its
                agents, through which Customer is provided access to the
                Software via the Internet. The FastPagos Service may include any
                Third-Party Software that is embedded within the FastPagos
                Service or is made available to Customer under the terms of this
                Agreement, but excludes Third-Party Software that is licensed or
                provided to Customer under a separate license or subscription
                agreement.
              </li>
              <li className="mb-4 text-wrap">
                1.9 “Prohibited Products” means the products listed on Appendix
                A hereto.
              </li>
              <li className="mb-4 text-wrap">
                1.10 “Software” means the hosted computer software that Customer
                is granted the right to access and use as part of the FastPagos
                Service, including all systems, modules, web pages, websites,
                databases, software code, technology, etc., provided by
                FastPagos. “Software” includes all modifications, and all
                documentation and updates thereof.
              </li>
              <li className="mb-4 text-wrap">
                1.11 “Third-Party Software” means any software or technology
                (including open source software) that is not part of the
                FastPagos Family Products.
              </li>
            </ul>
          </p>
        </div>
      </div>
      {/* page 2 */}
      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
            2. FREE TRIAL
          </h1>
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                2.1 Free Trial Terms. From time to time, FastPagos will make the
                FastPagos Service available to Customer on a trial basis free of
                charge, in the form of a private, trial demo account, until the
                earlier of (a) the end of the free trial period for which
                Customer has registered or is registering to use the FastPagos
                Service, or (b) the automatic start date on which the trial
                converts to a paid subscription. Additional trial terms and
                conditions may appear on the trial registration web page or
                documentation. Any such additional terms and conditions are
                incorporated into this Agreement by reference and are legally
                binding. DURING THE FREE TRIAL PERIOD THE FastPagos SERVICE IS
                PROVIDED “AS IS” WITHOUT ANY WARRANTIES OR INDEMNITIES OF ANY
                KIND.
              </li>
              <li className="mb-4 text-wrap">
                2.2 Automatic Subscription. YOU EXPRESSLY ACKNOWLEDGE,
                UNDERSTAND, AND AGREE THAT THE FREE TRIAL INVOLVES A “NEGATIVE
                OPTION” (I.E., AUTOMATIC ENROLLMENT IN A SUBSCRIPTION OF THE
                FASTPAGOS SERVICE), AND THAT YOU MAY BE LIABLE AND RESPONSIBLE
                FOR THE PAYMENT OF FUTURE SUBSCRIPTION FEES UNDER THE TERMS OF
                THIS AGREEMENT IF YOU FAIL TO TERMINATE THE FREE TRIAL BEFORE
                ITS EXPIRATION DATE. YOU MAY CANCEL THE FREE TRIAL OR
                SUBSCRIPTION AT ANY TIME AS SPECIFIED HEREIN. HOWEVER, IF YOU
                FAIL TO CANCEL WITHIN 14 DAYS OF YOUR ORIGINAL 14 DAY TRIAL, YOU
                WILL BE CHARGED THE FULL RETAIL PRICE FOR MONTHLY SERVICES GOING
                FORWARD UNTIL YOU CANCEL.
              </li>
              <li className="mb-4 text-wrap">
                2.3 Trial Data. ANY DATA ENTERED INTO THE FASTPAGOS ACCOUNT
                DURING THE FREE TRIAL PERIOD MAY BE TEMPORARY ONLY. UPON
                ACTIVATION OF THE PAID SUBSCRIPTION, CUSTOMER MAY BE PROMPTED TO
                CHOOSE IF IT WISHES TO RETAIN PRIOR ENTERED DATA, OR IF SUCH
                DATA SHALL BE PURGED. IF CUSTOMER DOES NOT ACTIVATE THE
                FastPagos SERVICE (EITHER AUTOMATICALLY OR MANUALLY) AS A PAID
                SUBSCRIPTION OR DOES NOT ELECT TO RETAIN THE TRIAL PERIOD DATA,
                FastPagos MAY PURGE AND DELETE THAT TRIAL PERIOD DATA AT ANY
                TIME AFTER EXPIRATION OF THE TRIAL PERIOD.
              </li>
            </ul>
            <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
              3. ACCESS TO FASTPAGOS SERVICE AND SOFTWARE
            </h1>
            <p className="mt-4 text-sm text-wrap">
              <ul>
                <li className="mb-4 text-wrap">
                  3.1 FastPagos Service. FastPagos grants Customer the
                  non-exclusive and non-transferable right and license to access
                  and use the FastPagos Service, including the Software, during
                  the term of this Agreement, subject to the terms and
                  conditions of this Agreement. FastPagos will make the
                  FastPagos Service available for Customer and its Authorized
                  Users to access during the term of this Agreement, subject to
                  the terms and limitations set forth herein. The FastPagos
                  Service may be hosted on FastPagos’s servers or, at
                  FastPagos’s option, on the servers of a third party that is in
                  the business of hosting web-based applications.
                </li>
                <li className="mb-4 text-wrap">
                  3.2 Access and URL. During the term of this Agreement, from
                  and after the Activation Date and completion of initial
                  configuration of the FastPagos Service for Customer, and
                  provided that Customer has paid all fees due and owing and is
                  in compliance with the terms of the Agreement, Customer and
                  its Authorized Users will be able to access the FastPagos
                  Service and use the Software. FastPagos will provide a URL for
                  a website for use by Customer in the form of a sub-domain of
                  FastPagos’s registered URL as chosen by FastPagos. If Customer
                  chooses to sub-mask or forward a different URL to the URL
                  provided by FastPagos, then Customer is solely responsible to
                  independently purchase, retain ownership of and uphold terms
                  and conditions of such URL.
                </li>
                <li className="mb-4 text-wrap">
                  3.3 Authorized Users. Authorized Users are granted a
                  nonexclusive, non-transferable right to access and use the
                  FastPagos Service for the sole benefit of Customer, subject to
                  the terms of FastPagos’s End User Terms of Service, the
                  current version of which is attached as Exhibit 1. Each
                  Authorized User must accept the End User Terms of Service
                  prior to accessing the FastPagos Service. The End User Terms
                  of Service may be modified from time to time in FastPagos’s
                  discretion; updated versions shall be effective upon notice to
                  Authorized Users. FastPagos’s commitments, representations,
                  and indemnities set forth in this Agreement apply only to
                  Customer itself.{" "}
                </li>
                <li className="mb-4 text-wrap">
                  3.4 Restrictions. Customer and its Authorized Users will
                  comply with the following restrictions and limitations: (a)
                  not market, advertise, offer for sale, or sell any of the
                  Prohibited Products in correlation with use of the FastPagos
                  Service and the Software. (b) not copy or modify the content
                  on the FastPagos websites, other than Customer Data, or any
                  other materials or other proprietary documents provided by
                  FastPagos; (c) not modify, alter, create derivative works of,
                  reverse engineer, decompile or disassemble the Software; (d)
                  not sublicense, distribute or sell the FastPagos Service or
                  Software or Customer’s rights thereto, or allow any third
                  parties to use or access the FastPagos Service or Software.
                  For clarity, Customer and its Authorized Users are restricted
                  to using the FastPagos Service and Software to service the
                  provision of its own websites to the consuming public, and
                  offer for sale, and sale, its own proprietary products through
                  the website. (e) take all reasonable precautions to prevent
                  Customer’s employees and consultants from making unauthorized
                  copies of the Software, or other FastPagos materials or
                  intellectual property, or misusing the FastPagos Service or
                  such FastPagos materials or intellectual property in any way
                  that would constitute a breach of this Agreement.{" "}
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      {/* page 3 */}
      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                If Customer discovers any breaches of this Agreement by it or
                breach of the End User Terms of Service by its Authorized Users,
                it will promptly notify FastPagos and take commercially
                reasonable actions to resolve the problem, including any actions
                reasonably requested by FastPagos, as soon as reasonably
                possible. FastPagos reserves the right to audit Customer’s use
                of the FastPagos Service, upon twenty-four (24) hours’ prior
                written notice to Customer, to confirm that Customer’s use of
                the FastPagos Service is in compliance with the terms of this
                Agreement. Customer acknowledges the FastPagos may also monitor
                the FastPagos Service and Customer’s use thereof on FastPagos’s
                systems.
              </li>
              <li className="mb-4 text-wrap">
                3.5 Administrative User. Customer will designate one Authorized
                User to be its Administrative Userand may designate up to two
                additional Authorized Users as backup Administrative Users. An
                authorized representative of Customer will notify FastPagos of
                the name and contact information for the Administrative Users,
                and any changes to the persons designated as primary or backup
                Administrative Users. The Administrative User is given
                administrative access to Customer’s account on the FastPagos
                Service and is responsible for granting or restricting FastPagos
                Service access for other Authorized Users. The Administrative
                User is also the primary contact person for Customer with
                FastPagos or its agents, and such person’s instructions and
                requests to FastPagos or its agents will have priority over the
                instructions or requests of any other employee or representative
                of Customer.
              </li>
              <li className="mb-4 text-wrap">
                3.6 Business Opportunities for Customer. FastPagos may from time
                to time notify Customer of services, products, or other business
                opportunities arising out of FastPagos’s business, including its
                dealings with other customers and vendors of FastPagos. In each
                case Customer will have the right to elect whether or not to
                participate in such transaction or to receive additional
                information, on an “opt-in” basis.
              </li>
              <li className="mb-4 text-wrap">
                3.7 Third-Party Software. Any open source components of the
                FastPagos Service are subject to the applicable third-party open
                source license terms; FastPagos will use reasonable efforts to
                make such terms available upon request. Other Third-Party
                Software that is embedded in the FastPagos Service Software, or
                is provided by FastPagos as an integrated part of the FastPagos
                Service, is provided by FastPagos to Customer pursuant to the
                applicable terms of this Agreement, unless a separate
                third-party license or subscription agreement for such
                Third-Party Software is provided to Customer. Third-Party
                Software is authorized only for use in connection with the
                FastPagos Service, unless otherwise permitted under an open
                source license.
              </li>
            </ul>
            <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
              4. OWNERSHIP
            </h1>
            <p className="mt-4 text-sm text-wrap">
              <ul>
                <li className="mb-4 text-wrap">
                  4.1 FastPagos Ownership. FastPagos and/or its licensors retain
                  all right, title and interest, including without limitation
                  all patents and patent rights, trademarks, service marks,
                  copyrights, trade secrets and other proprietary rights, in and
                  to the FastPagos Service, including the Software,
                  documentation and all content provided by FastPagos as part of
                  the FastPagos Service, including any customized software or
                  other derivative works, subject to the grants of rights and
                  licenses set forth in this Agreement. FastPagos also owns all
                  right, title and interest in and to (i) the usage data
                  relating to its customers’ use of the FastPagos Service
                  (“Usage Data”), and (ii) the results or information provided
                  by third party vendors in response to actions or queries of
                  customers (excluding any Customer Data that is contained
                  within the vendor’s response). FastPagos specifically reserves
                  all rights not expressly granted to Customer in this
                  Agreement.
                </li>
                <li className="mb-4 text-wrap">
                  4.2 Customer Data. Customer owns and shall retain all right,
                  title and interest in and to Customer Data, subject to
                  FastPagos’s rights as set forth below. FastPagos will use
                  commercially reasonable efforts to safeguard the security,
                  confidentiality and integrity of Customer Data.Customer grants
                  FastPagos the right to use, reproduce, modify and distribute
                  Customer Data as necessary or appropriate to transmit, store,
                  encrypt, calculate, and analyze the Customer Data, create and
                  distribute reports, and to provide, modify and improve the
                  FastPagos Service and Software. To the extent permitted by
                  applicable law, Customer also grants FastPagos the right to
                  share Customer Data with third parties in connection with
                  FastPagos’s general activities of conducting business,
                  including providing Customer with possible solutions to their
                  business needs, and developing and providing third party
                  integrations with the Software (pulling credit, data decoding,
                  data lookup, automated telephone calls, merchant services,
                  etc.).
                </li>
                <li className="mb-4 text-wrap">
                  4.3 Usage Data and De-Identified Data. FastPagos has the right
                  to monitor Customer’s and its Authorized Users’ use of the
                  FastPagos Service to obtain Usage Data, including usage
                  patterns (e.g., levels of use based upon days of the week and
                  times), and level of usage for different functions of the
                  FastPagos Service as examples. FastPagos also has the right to
                  collect, aggregate and remove all personally identifiable
                  information from Customer Data, and to retain, use and
                  disclose such de-identified data (the “De-Identified Data”)
                  for any purpose permitted by law, including without limitation
                  benchmarking, product and service development, development of
                  best practices, making it available to third parties, and
                  research and statistical purposes without reimbursement or
                  notification to, or consent or authorization from, Customer.
                  FastPagos shall own all De-Identified Data, including any
                  calculations, functions, features, or other modifications of
                  the Customer Data, excluding the Customer Data in its raw
                  form.
                </li>
                <li className="mb-4 text-wrap">
                  4.4 Feedback and Suggestions.If Customer or its Authorized
                  Users provide any suggestions, ideas or feedback to FastPagos
                  (“Feedback”), FastPagos shall have a royalty-free, worldwide,
                  irrevocable, perpetual license to use such Feedback and
                  incorporate it into or use it to improve FastPagos’s software,
                  products and services. FastPagos shall exclusively own all
                  right, title and interest in and to any software and
                  intellectual property developed or delivered by FastPagos to
                  Customer in the performance of this Agreement, regardless of
                  whether it is based on or incorporates any Feedback, subject
                  to the rights granted herein to Customer.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      {/* page 4 */}
      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
            5. SUPPLIERS
          </h1>
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                5.1 As a reseller, FastPagos will provide the following
                Services:
              </li>
              <li className="mb-4 text-wrap">
                5.2 setting you up as a supplier of the Product on FastPagos's
                platform and establishing a Supplier Account which provides you
                with access to the Supplier Dashboard and allows you to view all
                sales made by FastPagos and the monies which are due to you for
                sales of the Product by FastPagos;
              </li>
              <li className="mb-4 text-wrap">
                5.3 acting as your non-exclusive reseller of the Product via
                FastPagos Checkout across all territories supported by FastPagos
              </li>
              <li className="mb-4 text-wrap">
                5.4 Facilitating product fulfillment by you to Buyers through
                connecting you to Buyers to enable such Buyer to download /
                access the Product, as applicable; and
              </li>

              <li className="mb-4 text-wrap">
                5.5 Order support and being responsible for all aspects of Sales
                Tax as between you, FastPagos and Buyers.
              </li>
              <li className="mb-4 text-wrap">
                5.6 From time to time, you may choose to activate optional
                Additional Services. The Additional Services may be activated
                via the Supplier Dashboard.
              </li>
            </ul>
            <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
              6. CUSTOMER OBLIGATIONS{" "}
            </h1>
            <p className="mt-4 text-sm text-wrap">
              <ul>
                <li className="mb-4 text-wrap">
                  6.1 Obligations. Customer will: (a) be responsible for its
                  Authorized Users’ compliance with this Agreement and the End
                  User Terms of Service; (b) be responsible for the accuracy,
                  quality and legality of Customer Data and of the means by
                  which Customer acquired such data; (c) use commercially
                  reasonable efforts to prevent unauthorized access to or use of
                  the FastPagos Service, and notify FastPagos promptly of any
                  such unauthorized access or use; (d) use the FastPagos Service
                  only in accordance with its documentation (as available)
                  and/or its intended purpose; (e) not make the FastPagos
                  Service available to anyone other than Authorized Users; (f)
                  not use the FastPagos Service to store or transmit infringing,
                  libelous, or otherwise unlawful or tortious material, or to
                  store or transmit material in violation of third-party privacy
                  rights; (g) not use the FastPagos Service to store or transmit
                  any virus, Trojan,worm, or other malicious or harmful computer
                  software code or routines; (h) not interfere with or disrupt
                  the integrity or performance of the FastPagos Service or third
                  party data contained therein; (i) not attempt to gain
                  unauthorized access to the FastPagos Service or its related
                  systems, networks, or other user data; (j) not engage in
                  copying, scraping, or reverse engineering of the Software or
                  any aspect of the FastPagos Service; (k) not use the FastPagos
                  Service for spamming, phishing, pharming, or for any obscene
                  or immoral purpose; (l) not use the FastPagos Service to
                  interfere with or circumvent the security features of the
                  FastPagos Service; (m) in the event of a dispute, not engage
                  in any conduct or communication, public or private, that
                  disparages FastPagos or any of its products or services (other
                  than within legal proceedings); and, (n) conduct itself, and
                  require that its employees conduct themselves, in a
                  professional manner during interactions with all FastPagos
                  personnel.
                </li>
                <li className="mb-4 text-wrap">
                  6.2 Usage Limitations. The FastPagos Service may be subject to
                  other usage limitations, such as, for example, limits on
                  storage space, on the number of calls Customer are permitted
                  to make against an API (application programming interface),
                  and, for any portion of the FastPagos Service that enables
                  Customer to provide Hosted Websites, on the number of page
                  views by visitors to those websites, or the size of the hosted
                  content.
                </li>
                <li className="mb-4 text-wrap">
                  6.3 Customer Security Requirements. Customer is solely
                  responsible for the security of data residing on server(s)
                  owned or operated by Customer or a third party designated by
                  Customer (e.g., a web hosting company, processor, or other
                  service provider), including being responsible for the
                  security of all data residing outside of the FastPagos
                  Service, as well as keeping confidential all usernames and
                  passwords of Authorized Users in order to avoid unauthorized
                  access to the FastPagos Service.
                </li>
                <li className="mb-4 text-wrap">
                  6.4 FastPagos Security Requirements. FastPagos will use
                  commercially reasonable, industry-standard methods to
                  safeguard the security of the FastPagos Service, including
                  processes of encryption of data, incident management policies,
                  data backup policies and other procedures to ensure both the
                  safety and security of the Customer Data residing in the
                  FastPagos Service. In compliance with PCI-DSS, FastPagos is
                  responsible for the security of cardholder data that resides
                  on the FastPagos Service. This includes securing cardholder
                  data that is stored, processed, or transmitted on behalf of
                  the Customer, to ensure the security of cardholder data and
                  its environment.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      {/* page 5 */}
      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
            7. TERMS OF PAYMENT{" "}
          </h1>
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                7.1 Fees & Costs: All pricing and transactions between FastPagos
                and Customer will be in U.S. Dollars, at the rates outlined on
                FastPagos’s pricing page. Customer is responsible for any and
                all fees incurred. All fees are charged at the time of purchase,
                subscription, usage, or approval of charge, as applicable to
                that particular type of fee. All fees are non-refundable unless
                otherwise expressly stated in this Agreement. If the fee
                category on FastPagos’s pricing page. has an asterisk next to
                the label of that fee type, that indicates that fee is a
                recurring monthly charge and not a per use fee. In the event of
                a conflict between the pricing page and any other terms in this
                Agreement, the terms of the pricing page shall control. The fees
                on FastPagos’s pricing page. and set forth below will apply for
                the Initial Term:
              </li>
              <li className="mb-4 text-wrap">
                7.1.1 Subscription Fee – This fee is a recurring monthly fee,
                starting on the Activation Date, calculated by consideration of
                a number of elements such as: number of Users, scope of
                services, etc., as listed on FastPagos’s pricing page..
              </li>
              <li className="mb-4 text-wrap">
                7.1.2 Transactions Fees – These are fees based on completed
                transactions performed in or through the FastPagos Service, as
                listed on FastPagos’s pricing page..{" "}
              </li>
              <li className="mb-4 text-wrap">
                7.1.3 Email Fees – These are fees based on option use of the
                email contact feature, as listed on FastPagos’s pricing page.
              </li>

              <li className="mb-4 text-wrap">
                7.1.4 New Features – FastPagos may add services or features to
                the FastPagos Service that will be available to Customer on an
                optional basis and may be subject to an additional fee. Fees for
                these new features or services will be specified in each case,
                and will be on a per use basis.
              </li>
              <li className="mb-4 text-wrap">
                7.1.5 FastPagos is not obligated to issue refunds or credits but
                may do so if a Seller or Affiliate commits fraud, if there are
                mistakes by FastPagos or a Seller, or if a Product violates the
                Terms of Service. FastPagos also has the right to delay payouts
                for risk assessment, suspend accounts, and refund payouts
                without warning if there is evidence of fraud.
              </li>
              <li className="mb-4 text-wrap">
                7.2 Pricing Changes All pricing is locked for the Initial Term.
                Upon renewal Subscription Fees, Billable Accounts, and Usage
                fees may increase.
              </li>
              <li className="mb-4 text-wrap">
                7.3 Billing Method. Customer is required to either pay in
                advance or be enrolled for automatic withdrawal with a valid
                payment profile. The primary payment profile that is on file
                will be automatically processed for payment on the day after the
                end of the Billing Cycle. If Customer elects automatic
                withdrawal, Customer hereby authorizes FastPagos to collect this
                automatic payment, using the payment profile selected by
                Customer, for the balance due each Billing Cycle. Customer’s
                activation payment profile will automatically be set to the
                primary payment profile upon activation, unless Customer changes
                that setting. Customer may manage this payment profile in the
                FastPagos Service, FastPagos will invoice Customer on a monthly
                basis all Fees in accordance with its pricing page, as
                applicable, for the prior month’s activity, Any such disputes
                must be reasonable and made in good faith. For amounts in
                dispute by Customer, Customer shall provide notice within five
                (5) business days of the end of the Billing Cycle. If Customer
                fails to give notice in this time frame, Customer waives all
                disputes.
              </li>

              <li className="mb-4 text-wrap">
                7.4 Remedies for Nonpayment. If Customer’s account is more than
                10 days past due and the overdue amounts have not been paid
                within ten (10) days after receipt of written notice of such
                breach (including by an on-screen non-payment notification to
                Customer within the FastPagos Service), FastPagos has the option
                and right to require payment on the account by partially or
                fully suspending and blocking Customer’s and its Authorized
                Users’ access to the FastPagos Service until all past-due
                amounts are paid. Customer’s account may be assessed additional
                fees for blocking or unblocking the account due to late payment,
                and Customer hereby consents to this collection practice. This
                express statement of remedy is not a waiver of any other
                remedies available the FastPagos in law or equity.
              </li>
              <li className="mb-4 text-wrap">
                7.5 Taxes. Customer is responsible for all applicable taxes on
                the fees paid by Customer to FastPagos, including, without
                limitation, any and all sales, use, and value-added taxes,
                (excluding taxes on FastPagos’s net income).Customer is solely
                responsible for any sales/use taxes, even if not collected by
                FastPagos. To the extent required by governing law, FastPagos
                will invoice Customer for any sales or use taxes applicable to
                the FastPagos Service and remit such amounts to the applicable
                governmental authorities. If Customer is a tax-exempt entity,
                Customer will provide a tax-exemption certificate to FastPagos.
              </li>
            </ul>
          </p>
        </div>
      </div>

      {/* page 6 */}
      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
            8. CONFIDENTIAL INFORMATION
          </h1>
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                8.1 General Confidentiality Obligations. The party receiving
                Confidential Information will not disclose it to any person or
                use it for any purpose, except as expressly permitted by this
                Agreement. The receiving party may disclose Confidential
                Information only to its employees, representatives and
                contractors who need to know such information and who are bound
                to keep such information confidential. The receiving party will
                give Confidential Information at least the same level of
                protection as it gives its own confidential information of
                similar nature or sensitivity, but not less than a reasonable
                level of protection. The receiving party will maintain
                Confidential Information in a safe and secure place and will not
                copy such information, except to the extent reasonably necessary
                for the purposes of this Agreement.{" "}
              </li>
              <li className="mb-4 text-wrap">
                8.2 Personal Data and Privacy. FastPagos agrees to maintain
                commercially reasonable administrative, physical, and technical
                safeguards for protection of the security, confidentiality and
                integrity of Customer Data, including personal information and
                personal data of Users and other individuals (“Personal Data”).
                FastPagos will not disclose or use Personal Data except (i) as
                set forth in this Agreement or the then-current Privacy Policy
                on FastPagos’s website, (ii) as compelled by law, (iii) as
                expressly permitted or instructed by Customer, or (iv) as
                reasonably necessary in order to provide the FastPagos Service
                and other services in connection with this Agreement. FastPagos
                shall require its employees and contractors to be subject to
                confidentiality undertakings with respect to Customer Data,
                including Personal Data.
              </li>
            </ul>
            <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
              9. WARRANTIES AND LIMITATION OF LIABILITIES
            </h1>
            <p className="mt-4 text-sm text-wrap">
              <ul>
                <li className="mb-4 text-wrap">
                  9.1 Customer Warranties. Customer represents and warrants
                  that: (a) Customer has the necessary right, power and
                  authority to execute this Agreement and to perform Customer’s
                  obligations herein; (b) no authorization or approval from any
                  third party is required in connection with Customer’s
                  execution, delivery or performance of this Agreement; (c) this
                  Agreement constitutes a legal, valid and binding obligation of
                  Customer, enforceable against Customer in accordance with its
                  terms; (d) Customer’s obligations under this Agreement do not
                  violate any law or breach any other agreement to which
                  Customer is bound; (e) all representations and statements made
                  by Customer in this Agreement, or in any other document
                  relating hereto by Customer or on Customer’s behalf, are true,
                  accurate and complete in all material respects; (f) Customer
                  is engaged in a lawful business that includes the sale of
                  products and/or services, and Customer has such permits and
                  licenses as are required to conduct its business under the
                  laws of all applicable jurisdictions in which Customer
                  conducts such business; and (g) Customer will comply, at its
                  sole expense, with all federal, state and local laws,
                  policies, guidelines, regulations, ordinances or rules
                  applicable to Customer in connection with this Agreement and
                  its use of the FastPagos Service.
                </li>
                <li className="mb-4 text-wrap">
                  9.2 FastPagos Warranties. FastPagos represents and warrants
                  that: (a) FastPagos has the necessary right, power and
                  authority to execute this Agreement, to grant the rights and
                  licenses herein granted to Customer, and to perform
                  FastPagos’s obligations herein; (b) no authorization or
                  approval from any third party is required in connection with
                  FastPagos’s execution, delivery or performance of this
                  Agreement; (c) this Agreement constitutes a legal, valid and
                  binding obligation of FastPagos, enforceable against FastPagos
                  in accordance with its terms; (d) the FastPagos Service will
                  conform in all material respects with its intended purpose. In
                  the event of a breach of this warranty, FastPagos’s exclusive
                  obligation and liability will be (i) to repair or correct the
                  FastPagos Service so that it conforms to this warranty, or
                  (ii) if the FastPagos Service cannot be or is not so corrected
                  within a reasonable time, either party may elect to terminate
                  this Agreement and Customer shall receive a refund of any
                  prepaid amounts for the FastPagos Service from the date of
                  termination; and (e) From and after the Activation Date,
                  FastPagos will use commercially reasonable efforts to provide
                  Customer with access the FastPagos Service excluding downtime
                  (i) scheduled in advance for maintenance on a periodic basis,
                  (ii) due to unscheduled emergency maintenance, (iii) due to
                  faults caused by Customer or Customer’s system, or (iv) due to
                  other causes outside of the reasonable control of FastPagos,
                  including without limitation malfunction or cessation of
                  Internet services by any third party network or ISPor service
                  interruptions caused by the third party cloud storage service.
                  To the extent reasonably feasible, FastPagos will provide
                  Customer reasonable advance notice for emergency maintenance
                  or Software performance interference issues, which will be
                  published on the FastPagos Service’s status page. (f)
                  FastPagos’s warranties under Sections 8.2(d) and (e) are
                  conditional upon Customer having in place, at a minimum,
                  FastPagos’s then-current recommended infrastructure
                  configurations.
                </li>
                <li className="mb-4 text-wrap">
                  9.3 Limitation of Warranties; Disclaimers. FastPagos does not
                  warrant or guarantee that the FastPagos Service will be
                  uninterrupted, error-free, or free from any potential or
                  actual security threats. The FastPagos Service and Software,
                  and other services of FastPagos, are provided to Customer and
                  its Authorized Users on an “AS IS, AS AVAILABLE” basis.
                  FastPagos AND ITS LICENSORS SPECIFICALLY DISCLAIM ALL OTHER
                  WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITH
                  RESPECT TO MERCHANTABILITY, TITLE, NON-INFRINGEMENT OR FITNESS
                  OF THE FastPagos SERVICE FOR ANY PARTICULAR PURPOSE OR
                  INTENDED USE. FastPagos MAKES NO WARRANTIES WHATSOEVER AND IS
                  NOT LIABLE FOR ANY LOSS OR DAMAGE THAT MAY BE INCURRED BY
                  CUSTOMER AS A RESULT OF USING ANY THIRD-PARTY SERVICE OR
                  SOFTWARE, EVEN IF LINKED TO OR INTEGRATED IN THE FastPagos
                  SERVICE. FastPagos is not responsible or liable for damage,
                  malfunction, or performance failures resulting from misuse,
                  physical abuse, improper operation, the environment or other
                  causes beyond FastPagos’s exclusive control. No employee of
                  FastPagos or any third party has the right to make any
                  representation or warranty regarding the FastPagos Service,
                  except as expressly set forth in this Agreement. Without
                  limiting the foregoing, and except as otherwise expressly set
                  forth in this Agreement, FastPagos does not make any
                  representation, warranty or guarantee as to the results that
                  may be obtained from Customer’s use of the FastPagos Service
                  or as to the accuracy or reliability of any information
                  therein, or with respect to any third party product or
                  service, whether integrated with the FastPagos Service or not,
                  or recommendations or information offered by any FastPagos
                  personnel or third parties. Customer expressly acknowledges
                  and agrees that Customer’s use of the FastPagos Service does
                  not in any way guarantee the security or reliability of
                  Customer’s website. Customer understands and agrees that
                  FastPagos shall bear no risk with respect to Customer’s sale,
                  products or services, including any risk associated with the
                  security of Customer’s website, credit card fraud or
                  chargebacks, or any risk associated with Customer’s failure to
                  register with the appropriate governmental agencies or
                  obtaining the appropriate licenses to conduct business,
                  including charging interest or finance charges, or any other
                  regulatory or legal requirements. FastPagos is not responsible
                  and does not assume any obligations for any regulatory
                  compliance or disclosures required of Customer. Customer
                  expressly agrees that FastPagos shall not be liable for any
                  loss or damages whatsoever arising from or caused by (i)
                  Customer’s failure to properly activate, integrate, use or
                  manage the FastPagos Service; (ii) any fraudulent
                  transactions; (iii) disruption of the FastPagos Service
                  attributable to a Force Majeure event; (iv) actions or
                  inactions of any third party, including without limitation,
                  merchant service providers, payment processors, bank URL
                  support, email systems, or any products or services with which
                  the FastPagos Service is integrated; (v) any person’s
                  unauthorized access to Customer Data (including credit card
                  number and other personally identifiable information),
                  transaction data or personal information, except if such
                  access is due solely to FastPagos’s grossly negligent or
                  willful misconduct; or (vi) Third-Party Software that is not
                  embedded within the FastPagos Service.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      {/* page 7 */}
      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                9.4 Limitations of Liability. IN NO EVENT WILL FastPagos, ITS
                AFFILIATES OR LICENSORS BE LIABLE FOR LOST DATA, LOST PROFITS OR
                ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
                DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT,
                INCLUDING WITHOUT LIMITATION THE FURNISHING, PERFORMANCE, OR USE
                OF ANY SOFTWARE OR SERVICES PROVIDED FOR IN THIS AGREEMENT.
                FastPagos’S AND ITS AFFILIATES’ AND LICENSORS’ TOTAL AGGREGATE
                LIABILITY ARISING OUT OF THIS AGREEMENT, REGARDLESS OF THE FORM
                OF THE CLAIM(S), INCLUDING INDEMNIFICATION, WILL NOT IN ANY
                EVENT EXCEED THE AMOUNTS PAID BY CUSTOMER UNDER THIS AGREEMENT
                DURING THE ONE-MONTH PERIOD PRIOR TO THE DATE THE CAUSE OF
                ACTION AROSE; PROVIDED, HOWEVER, THAT IN THE CASE OF A CLAIM
                RELATING TO ANY SERVICES FOR WHICH A SEPARATE ONE-TIME FEE WAS
                CHARGED, THE CAP ON LIABILITY SHALL BE THE AMOUNT OF SUCH FEE.
                This limitation shall apply whether or not the alleged breach by
                FastPagos is a breach of a fundamental condition or fundamental
                term.
              </li>
              <li className="mb-4 text-wrap">
                9.5 Legal Compliance; Use of FastPagos Forms. Customer
                acknowledges that FastPagos’s personnel are not financial
                experts, attorneys, accountants or experts on the applicable
                laws of any state or jurisdiction. FastPagos personnel may
                consult with Customer’s Authorized Users and provide assistance
                and recommendations, but Customer is solely responsible for
                compliance with any applicable laws, regulations and
                professional standards by Customer and its Authorized Users.
                This includes, without limitation, whether it is legal or
                appropriate, under the laws and regulations that govern
                Customer. THE FINAL DECISION ABOUT ANY ASPECT OF CUSTOMER’S
                BUSINESS, INCLUDING WITHOUT LIMITATION COMPLIANCE WITH LAWS IN
                CONNECTION WITH CUSTOMER’S USE OF THE FastPagos SERVICE, IS THE
                SOLE AND EXCLUSIVE RESPONSIBILITY OF CUSTOMER.
              </li>
            </ul>
            <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
              10. INDEMNIFICATION
            </h1>
            <p className="mt-4 text-sm text-wrap">
              <ul>
                <li className="mb-4 text-wrap">
                  10.1 By FastPagos. FastPagos will defend Customer against any
                  and all third party claims or suits (each a “Claim”) that the
                  FastPagos Service infringes any third party U.S. patent that
                  has issued as of the Effective Date, copyright or trademark,
                  or misappropriates any trade secret, and FastPagos will pay
                  any liabilities, damages, costs and expenses (including
                  reasonable attorneys’ fees) finally awarded in connection with
                  such Claim or paid in settlement. If the FastPagos Service is
                  finally held or reasonably believed by FastPagos to infringe,
                  FastPagos shall use reasonable efforts to obtain a license
                  under the rights that have been infringed, to modify the
                  FastPagos Service so it is non infringing or to provide to
                  Customer a substitute service and/or software that is
                  noninfringing; provided that if such options are not
                  commercially reasonable, FastPagos may terminate the
                  applicable FastPagos Service or this Agreement upon written
                  notice to Customer, in which event FastPagos shall refund to
                  Customer all prepaid fees paid for any period after
                  termination of this Agreement, as well as any period prior to
                  termination during which Customer was prevented from accessing
                  the FastPagos Service. FastPagos shall have no liability for
                  infringement claims arising out of or related to any
                  Third-Party Software, or arising out of modification of the
                  FastPagos Service by any party other than FastPagos or the
                  combination or use of the FastPagos Service with any software,
                  equipment, product or process not furnished by FastPagos, or
                  arising out of any unauthorized use of the FastPagos Service
                  by Customer, if use of the FastPagos Service alone, as
                  authorized, and in its current, unmodified form would not have
                  been an infringement. THIS SECTION STATES FASTPAGOS’ AND ITS
                  LICENSORS’ ENTIRE OBLIGATION WITH RESPECT TO ANY CLAIM FOR
                  INFRINGEMENT OR MISAPPROPRIATION OF ANY THIRD PARTY
                  INTELLECTUAL PROPERTY RIGHTS.
                </li>
                <li className="mb-4 text-wrap">
                  10.2 By Customer. If any action is instituted by a third party
                  against FastPagos (a) arising out of or relating to Customer’s
                  use of the FastPagos Service, including without limitation (i)
                  any breach or alleged breach by Customer of any of its
                  representations, warranties, or obligations set forth in this
                  Agreement; (ii) any breach of any applicable law or regulation
                  governing or otherwise applicable to Customer’s industry,
                  business, or operations; (iii) any damage or loss caused by
                  negligence, fraud, dishonesty or willful misconduct by
                  Customer or its employees, or agents; or (iv) any claims
                  associated with the provision of services by Customer to its
                  Borrowers; or (b) alleging that the Customer Data, or the use
                  of Customer Data pursuant to this Agreement, infringes the
                  intellectual property or other right of a third party or
                  otherwise causes harm to a third party, Customer will defend
                  such action at its own expense on behalf of FastPagos and
                  shall pay all damages attributable to such claim which are
                  finally awarded against FastPagos or paid in settlement of
                  such claim.
                </li>
                <li className="mb-4 text-wrap">
                  10.3 Indemnification Procedure. Any party that is seeking to
                  be indemnified under this Section 9 (an “Indemnified Party”)
                  for a third party Claim must (i) promptly notify the other
                  party (the “Indemnifying Party”) of the Claim; and (ii) give
                  the Indemnifying Party the sole control over the defense of
                  such Claim. However, if an Indemnified Party fails to notify
                  the Indemnifying Party promptly, the Indemnifying Party will
                  be relieved of its obligations under this Section 9 only if
                  and to the extent that its ability to defend the Claim is
                  materially prejudiced by such failure. The Indemnifying Party
                  may settle or compromise a Claim without the Indemnified
                  Party’s prior approval of any such settlement or compromise
                  only if (a) such settlement involves no finding or admission
                  of any breach by an Indemnified Party of any obligation to any
                  third party, (b) such settlement has no effect on any other
                  claim that may be made against an Indemnified Party or any
                  defense that an Indemnified Party may assert in any such
                  claim, and (c) the sole relief provided in connection with
                  such settlement is monetary damages that are paid in full by
                  the Indemnifying Party. Upon the Indemnifying Party’s
                  assumption of the defense of such Claim, the Indemnified Party
                  will reasonably cooperate with the Indemnifying Party in such
                  defense, at the Indemnifying Party’s expense. The Indemnified
                  Party may, at its option and expense, participate in the
                  defense of the Claim with counsel of its own choosing.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      {/* page 8 */}

      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
            11. TERM AND TERMINATION
          </h1>
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                11.1 Term. There is no set term or automatic expiration. The
                subscription remains active until cancelled.
              </li>
              <li className="mb-4 text-wrap">
                11.2 Cancellation without Cause. Customer may cancel the
                subscription at any time with or without cause. However, the
                subscription will typically remain active until the end of a
                Billing Cycle and all fees incurred during the billing cycle
                must be paid.
              </li>
              <li className="mb-4 text-wrap">
                11.3 Termination for Cause. FastPagos may terminate this
                Agreement at any time if Customer party commits a material
                breach of this Agreement and does not cure such breach within
                fifteen (15) days of written notice specifying the nature of
                such breach (except for breaches by a party of its
                confidentiality obligations or payment obligations, for which
                the cure period will be five (5) business days after receipt of
                written notice of such breach, including by an on-screen
                non-payment notification to Customer within the FastPagos
                Service). A confidentiality breach is deemed cured, for purposes
                of this Section 10.3, if the Customer (i) mitigates the damages
                resulting from the breach to the extent reasonably feasible; and
                (ii) takes reasonable steps, such as modifying its internal
                policies and practices, to prevent such a breach from
                reoccurring. Notwithstanding the foregoing, FastPagos may, in
                its reasonable business judgment, determine that a
                confidentiality breach was sufficiently material that it wishes
                to terminate this Agreement without permitting a cure, in which
                case the FastPagos shall so notify Customer and this Agreement
                shall terminate at the end of the five (5) day notice period.
                FastPagos may also terminate or suspend Customer’s account and
                this Agreement immediately, without prior notice, if FastPagos
                reasonably determines that Customer is conducting an illegal
                business or if FastPagos is instructed to terminate or suspend
                Customer’s account by federal or state regulatory authorities.
                If the reason for a suspension of Customer’s account is
                resolved, FastPagos agrees to promptly restore access to
                Customer’s account.
              </li>
              <li className="mb-4 text-wrap">
                11.4 Effect of Termination. In the event of termination of this
                Agreement for any reason, Customer shall immediately cease using
                the FastPagos Service. After termination of this Agreement
                (including any applicable transition period (i.e. through the
                end of the Billing Cycle)), Customer will not have any access to
                the FastPagos Service or the Customer Data stored therein; it is
                Customer’s sole responsibility to copy or remove such Customer
                Data from the FastPagos Service prior to termination. After
                termination, FastPagos has no obligation whatsoever to maintain
                or store Customer Data and FastPagos will promptly delete or
                remove Customer Data from its system. FastPagos reserves the
                right to store the Customer Data after termination to comply
                with applicable laws and regulations. Each party shall promptly
                return to the other party or destroy all Confidential
                Information of the other party, and upon request of the other
                party will certify such return or destruction, provided that if
                retention of any Confidential Information is required by law or
                practice, then such retained Confidential Information shall be
                described in the applicable certification and remain subject to
                the terms of this Agreement, including. Customer shall pay all
                accrued charges and fees within thirty (30) days of the date of
                termination. Sections 3.4, 4, 7, 6.4, 6.5, 6.6, 8.3, 8.4, 9,
                11.2 and 11.3 shall survive any termination of this Agreement.
              </li>
            </ul>
            <h1 className="flex  text-[1.2rem] font-thin justify-start items-start">
              12. MISCELLANEOUS
            </h1>
            <p className="mt-4 text-sm text-wrap">
              <ul>
                <li className="mb-4 text-wrap">
                  12.1 Assignment. Customer will not assign this Agreement or
                  transfer, lease, export or grant a sublicense of the rights
                  granted herein to any third party (other than designating
                  Authorized Users), without FastPagos’s prior written consent.
                  FastPagos’s consent to an assignment of this Agreement by
                  Customer is subject to FastPagos’s review and approval of the
                  proposed assignee. Subject to the foregoing, this Agreement
                  shall inure to the benefit of, and shall be binding on, each
                  Party’s respective successors and permitted assigns.
                </li>
                <li className="mb-4 text-wrap">
                  12.2 Governing Law. This Agreement shall be governed by the
                  laws of State of Utah (excluding conflicts of laws
                  provisions). The parties submit to the exclusive jurisdiction
                  and venue of Utah State and federal courts with respect to any
                  action between the parties relating to this Agreement. The
                  prevailing party in any action shall be entitled to an award
                  of its reasonable costs and attorneys’ fees from the other
                  party.
                </li>
                <li className="mb-4 text-wrap">
                  12.3 Injunctive Relief. Customer acknowledges that the
                  FastPagos Service and other Confidential Information are
                  highly valuable to FastPagos and its licensors, and that money
                  damages would not be a sufficient remedy for any breach of
                  Customer’s obligations herein with respect to confidentiality
                  or misuse of FastPagos’s or its licensors’ proprietary
                  materials and information. Therefore, in the event of any
                  breach by Customer of its obligations with respect to the
                  scope of its rights or its confidentiality obligations,
                  FastPagos or its licensors shall be entitled to seek specific
                  injunctive relief as a remedy for such breach, in addition to
                  all other available legal or equitable remedies, without the
                  necessity of posting bond or other security, to the extent
                  permitted by law.
                </li>
                <li className="mb-4 text-wrap">
                  12.4 Publicity. Neither party shall release or use the other
                  party’s name, logo, trademark or other intellectual property,
                  or reference this Agreement in any manner, except as specified
                  in accordance with this Agreement, without the prior written
                  consent of the other party; provided, that the foregoing shall
                  not apply with respect to a party’s sales, marketing,
                  investor, regulatory and similar non-public materials and
                  discussions. Notwithstanding the foregoing, (i) FastPagos is
                  permitted to include Customer’s name and/or logo on customer
                  lists on FastPagos’s website and in other marketing materials;
                  and (ii) if requested by FastPagos, the parties will cooperate
                  in good faith to issue a press release that acknowledges the
                  partnership between the Parties within 60 days of the
                  Effective Date.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      {/* page 9 */}
      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                12.5 Severability. In the event that any of the terms or
                provisions herein are determined by a court of competent
                jurisdiction to be unenforceable or invalid for any reason
                whatsoever, such terms shall be severed, and the enforceability
                or validity of the remaining terms shall not be affected
                thereby.
              </li>
              <li className="mb-4 text-wrap">
                12.6 Entire Agreement; Amendment; Waiver. This Agreement,
                including the addenda and exhibits attached hereto, which are
                incorporated herein by reference, constitutes the entire
                agreement between the parties with respect to the subject matter
                hereof and that this Agreement supersedes all proposals, oral or
                written, all previous negotiations, and all other communications
                between the parties with respect to the subject matter hereof.
                Any terms and conditions of any purchase order or other
                instrument issued by Customer in connection with this Agreement
                which are in addition to or inconsistent with the terms and
                conditions of this Agreement shall not be binding on FastPagos
                and shall not apply to this Agreement unless mutually executed
                by Customer and FastPagos. Except as otherwise specifically set
                forth herein, this Agreement may be amended only by a written
                document signed by authorized representatives of both parties.
                The waiver by either party of any default, breach or obligation
                hereunder shall be ineffective unless in writing, and shall not
                constitute a waiver of any subsequent breach or default.
              </li>
              <li className="mb-4 text-wrap">
                12.7 Notices. All notices or other communications required under
                to this Agreement to FastPagos shall be in writing and shall be
                deemed given if delivered personally or mailed by registered or
                certified mail, return receipt requested, or by commercial
                overnight delivery service with provisions for a receipt, or by
                confirmed facsimile or e-mail, to the address of the receiving
                party set forth below or such other address a party may specify
                by written notice. Notwithstanding the foregoing, notices from
                Customer to FastPagos regarding changes to or termination of
                Customer’s subscription to the FastPagos Service may also be
                sent to the email address hello@fastpagos.com.
              </li>
              <li className="mb-4 text-wrap">
                FastPagos Contact Information: admin@fastpagos.in
              </li>
              <li className="mb-4 text-wrap">
                All notices to Customer shall be delivered through FastPagos’s
                internal communication portal, or otherwise via email to the
                email address provided by Customer upon subscription.
              </li>
              <li className="mb-4 text-wrap">
                12.8 Force Majeure. FastPagos shall be excused from delays or
                failure to perform the FastPagos Service or other services
                pursuant to this Agreement to the extent such delays or failure
                result from acts of nature, strikes, fire, riots, war, acts of
                public enemies, fires, pandemics, epidemics, labor disputes, or
                any other causes beyond its reasonable control (each a “Force
                Majeure” event). In the event of a delay in implementation and
                related services or other failure to perform obligations due to
                any Force Majeure event, the date or dates of performance of
                such services or obligations shall be extended for a period
                equal to the time lost by reason of the delay. If a party’s
                performance is affected by an event of Force Majeure, including
                Customer’s payment obligation, it will promptly inform the other
                party and will use commercially reasonable efforts to fulfill
                its obligations under this Agreement and to remove or avoid any
                disability and mitigate any damages caused by such event of
                Force Majeure at the earliest time and to the greatest extent as
                is reasonably feasible.
              </li>{" "}
              <li className="mb-4 text-wrap">
                12.9 Independent Contractors. The parties are independent
                contractors, and this Agreement shall not be construed to create
                any agency, fiduciary relationship, franchise, or partnership
                between them. Further, it is not the intention of this Agreement
                or of the Parties to confer a third party beneficiary right of
                action upon any third party or entity whatsoever, and nothing in
                this Agreement will be construed so as to confer upon any third
                party or entity other than the Parties hereto a right of action
                under this Agreement or in any manner whatsoever.{" "}
              </li>
              <li className="mb-4 text-wrap">
                12.10 Counterparts. This Agreement may be executed in any number
                of counterparts, and each such counterpart shall be deemed to be
                an original instrument, but all such counterparts together shall
                constitute but one agreement. This Agreement shall become
                effective when one or more counterparts have been signed by the
                parties hereto and delivered to the other parties, it being
                understood that the parties need not sign the same counterpart.
              </li>
              <li className="mb-4 text-wrap">
                12.11 Electronic Signatures. Each party agrees that if it or any
                other party draws, types or attaches its signature or any other
                text, symbol or image in a box or space associated with any
                Contract Document, such party is agreeing to be legally bound by
                such terms and conditions. The parties agree that such action
                constitutes an “Electronic Signature,” which shall have the same
                force and effect as an original signature.
              </li>
              <li className="mb-4 text-wrap">
                Appendix A – Prohibits Products/Services Products or content for
                which you do not hold proper license or intellectual property
                rights. Counterfeit goods. Any products restricted by our
                payment processing partners. Illegal or age restricted products
                such as: drugs and paraphernalia, alcohol, tobacco, vaping
                products, sexually-oriented or pornographic content. Regulated
                products such as: CBD, gambling, weapons, ammunition, pay to
                play auctions, sweepstakes, lotteries, donations,
                business-in-a-box, work-from-home, get-rich-quick schemes, etc.
                Regulated services such as: real estate, mortgage, lending,
                telemarketing, cellular/communication, door-to-door sales,
                bankruptcy, legal, merchant, debt-relief, collections,
                banking/financing, currency exchange, warranties, etc.
                Timeshares. Pharmacies, pharmaceuticals and nutriceuticals.
                Homework/Essay mills. Multi-level marketing, pyramid, or IBO
                schemes, Although FastPagos makes every effort to keep this list
                up to date, it may change without notice. All changes take
                effect immediately. It is your obligations to check back
                frequently.
              </li>
            </ul>
          </p>
        </div>
      </div>

      {/* page 10 */}
      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                EXHIBIT 1 End User Terms of Service
              </li>
              <li className="mb-4 text-wrap">
                PLEASE READ THESE TERMS OF SERVICE (THE “TERMS”) CAREFULLY
                BEFORE ACCEPTING THESE TERMS, UNDER WHICH YOU WILL BE AUTHORIZED
                TO USE THE FastPagos SERVICE PROVIDED BY FastPagos, LLC, A UTAH
                LIMITED LIABILITY COMPANY (“FastPagos”). BY CLICKING “I ACCEPT”
                OR BY USING THE FastPagos SERVICE, YOU INDICATE YOUR ACCEPTANCE
                OF THESE TERMS IN THEIR ENTIRETY. THESE TERMS SET FORTH YOUR
                LEGAL RIGHTS AND OBLIGATIONS RELATED TO THE FastPagos SERVICE.
                IF YOU DO NOT ACCEPT THESE TERMS, CLICK “I DO NOT ACCEPT” BELOW.
              </li>
              <li className="mb-4 text-wrap">
                These Terms are a legal agreement between you and FastPagos and
                govern your use of the FastPagos subscription service as an
                authorized user of a customer of FastPagos (the “Customer”),
                which has signed a separate subscription agreement with
                FastPagos. FastPagos makes its software subscription service
                (the “FastPagos Service”), including the related software (the
                “Software”) available to its customers and their authorized
                users. The FastPagos Service is accessible through FastPagos’s
                designated website and web portal (the “Website”), subject to
                the following terms and conditions. Any rights not expressly
                granted herein are reserved by FastPagos.
              </li>
              <li className="mb-4 text-wrap">
                Changes to Terms: FastPagos may update these Terms from time to
                time. You may be notified of such changes by email and/or
                FastPagos may require you to accept the new version of the Terms
                in order to continue accessing the FastPagos Service. If you
                object to any changes in these Terms, you may discontinue your
                use of the FastPagos Service. In consideration of FastPagos’s
                provision of the FastPagos Service and related services to you,
                you agree with FastPagos as follows:
              </li>
              <li className="mb-4 text-wrap">
                1. ACCESS TO FASTPAGOS SERVICE SOFTWARE AND MATERIALS. You will
                have access to the Software and materials made available in the
                FastPagos Service by FastPagos. You acknowledge and agree that
                the FastPagos Service, Website, Software, and other materials
                that may be made available as part of the FastPagos Service may
                be modified and updated from time to time, in FastPagos’s sole
                discretion. FastPagos does not make any commitment to you that
                it will maintain particular functions, features or materials as
                part of the FastPagos Service.
              </li>
              <li className="mb-4 text-wrap">
                2. ACCEPTABLE USE TERMS. As a condition of your use of the
                FastPagos Service (including the Website and Software), you
                warrant to FastPagos that you will use the FastPagos Service
                solely for the Customer’s business purposes, and you will not
                use the FastPagos Service or FastPagos materials for any purpose
                that is unlawful or that is prohibited by these Terms. Without
                limiting this warranty, you specifically agree: (a) You will not
                use the FastPagos Service in any manner which could damage,
                disable, overburden, or impair the FastPagos Service or
                interfere with any other person’s or customer’s use and
                enjoyment of the FastPagos Service. (b) You will not obtain or
                attempt to obtain any materials or information on or through the
                FastPagos Service through circumventing any access or use
                restrictions or by any other unauthorized methods, such as
                hacking or password mining. (c) You may not use any bots,
                spiders, page-scraping or other automated or manual processes or
                methods to copy or monitor this FastPagos Service or any of its
                contents. (d) Any content or data you upload or input in the
                FastPagos Service will not contain any obscene, immoral,
                libelous or unlawful material or any materials or instructions
                that may cause harm or injury, and will not violate any person’s
                right of privacy or any copyright, trademark, or other
                intellectual property rights. (e) You will not modify, publish,
                transmit, reverse engineer, participate in the transfer or sale,
                create derivative works, or in any other way use or exploit any
                of the content of the FastPagos Service or other FastPagos
                Materials other than for their authorized purposes. You will use
                FastPagos Materials solely for your own internal use, unless
                you’ve obtained the prior express written permission of
                FastPagos to authorize other users to access the FastPagos
                Service or use such FastPagos Materials. (f) You will not delete
                or alter any proprietary rights or attribution notices in any
                content or FastPagos Materials obtained through the FastPagos
                Service. (g) You agree that you do not acquire any ownership
                rights in any content provided by FastPagos, its licensors, or
                other third parties, or in any FastPagos Materials. We do not
                grant you any licenses, express or implied, to any FastPagos
                Materials except as expressly provided in these Terms, in
                connection with such content or materials, or as contained in a
                binding contract between you or the Customer and FastPagos.
              </li>{" "}
              <li className="mb-4 text-wrap">
                3. PERSONAL DATA AND PRIVACY. To operate the FastPagos Service
                as intended, FastPagos collects and processes certain personal
                data. Please refer to FastPagos’s Privacy Policy, which is
                incorporated into and forms a part of these Terms, and informs
                users of our policies and practices related to collection,
                storage, processing, destruction, and other use of personal
                data. You understand and agree that no data transmissions over
                the Internet can be guaranteed to be 100% secure and that
                FastPagos cannot
              </li>
              <li className="mb-4 text-wrap">
                4. OWNERSHIP AND DATA USAGE RIGHTS. (a) All content and
                materials on the Website or made available through the FastPagos
                Service, including the Software, text, graphics, logos and
                images (“FastPagos Materials”), are the property of FastPagos or
                its suppliers or licensors. All FastPagos Materials are
                protected by copyright and/or other laws that protect
                intellectual property and proprietary rights. You agree to
                comply with all copyright and other proprietary notices, legends
                or other restrictions applicable to any FastPagos Materials. (b)
                Any data, content, designs, data, information or materials you
                upload or post to the FastPagos Service on behalf of the
                Customer (“Customer Data”) remain the property of the Customer,
                subject to the rights of use contained in these Terms and
                FastPagos’s Privacy Policy. (c) The FastPagos Service tracks
                metadata and other usage data related to your use of the
                FastPagos Service (“Usage Data”) and shares such data with
                FastPagos. FastPagos shall own such Usage Data, other than the
                personal data incorporated therein. You agree that FastPagos
                shall have the perpetual right to collect, aggregate, use,
                distribute and sell such Usage Data for any legal purpose,
                including without limitation for the purposes of providing
                services and improving the FastPagos Service and FastPagos’s
                products and services generally. FastPagos may retain and use
                Usage Data permanently. To the extent such Usage Data contains
                any individually identifiable data or personal data, FastPagos
                shall not sell or otherwise provide such Usage Data to any third
                party unless the data been anonymized (e.g., no name or address
                attached to the particular data) and/or aggregated with other
                users’ data, so that it is not identifiable as to any particular
                person. Notwithstanding the foregoing, FastPagos may share Usage
                Data in its original form as necessary or appropriate to provide
                services to the Customer, to comply with legal obligations or to
                exercise its legal rights. (d) If you provide any suggestions,
                ideas or feedback to FastPagos (“Feedback”), FastPagos shall
                have a royalty-free, worldwide, irrevocable, perpetual license
                to use such Feedback and incorporate it into or use it to
                improve FastPagos’s software, products and services.
              </li>
            </ul>
          </p>
        </div>
      </div>

      {/* page 11 */}
      <div className="bg-white shadow-md rounded-lg m-4 flex flex-col justify-center items-center p-10 w-8/12">
        <div className="flex w-full mt-5 flex-col justify-start items-start">
          <p className="mt-4 text-sm text-wrap">
            <ul>
              <li className="mb-4 text-wrap">
                5. LINKS TO THIRD PARTY SITES. If you decide to access or use
                any third party websites linked to the FastPagos Service, you do
                this entirely at your own risk.
              </li>
              <li className="mb-4 text-wrap">
                6. TRADEMARKS. FastPagos’s name and its service marks,
                trademarks and logos, as well as any other FastPagos product and
                service names and logos displayed on the Website or FastPagos
                Service, are registered trademarks or trademarks of FastPagos
                Software, LLC or its affiliates. The names of third party
                companies and their trademarks are the property of their
                respective owners and may also be trademarks. FastPagos’s
                trademarks may be used publicly only with prior written
                permission from FastPagos
              </li>
              <li className="mb-4 text-wrap">
                7. TERM AND TERMINATION. These Terms shall remain in force until
                terminated by either party. These Terms and your right to access
                to the FastPagos Service automatically terminate, without notice
                to you, if FastPagos’s contract with the Customer terminates or
                if your employment or engagement with the Customer terminates.
                Either you or FastPagos may also terminate these Terms at any
                time, for any or no cause, by giving notice to the other party.
                Without limiting the foregoing, if you breach any of the terms
                of these Terms, FastPagos has the right, at its sole discretion
                and without prior notice, to suspend, modify, disable, or
                terminate your account or your use of the FastPagos Service.
                Upon termination, you will no longer have access to the
                FastPagos Service and the Customer Data thereon, except as
                described in this subsection. The Customer will have access to
                the Customer Data in accordance with the terms of its
                subscription agreement with FastPagos.
              </li>
              <li className="mb-4 text-wrap">
                8. INDEMNIFICATION. You agree to indemnify and hold FastPagos
                harmless from and against any and all actions, suits,
                proceedings, losses, liabilities, damages, costs, and expenses
                (including attorneys’ fees) that FastPagos may incur or suffer
                in connection with any data or content input or uploaded to the
                FastPagos Service, or by reason of the breach or alleged breach
                of any of your obligations under these Terms.
              </li>
              <li className="mb-4 text-wrap">
                9. ASSIGNMENT. You may not allow others to use your FastPagos
                Service account, and these Terms may not be assigned by you,
                without FastPagos’s prior written consent, which will not be
                unreasonably withheld. FastPagos may freely assign these Terms.
              </li>
              <li className="mb-4 text-wrap">
                10. WARRANTIES AND DISCLAIMERS. FASTPAGOS WARRANTS THE FastPagos
                SERVICE SOLELY TO THE CUSTOMER. NO WARRANTIES ARE MADE TO YOU OR
                ANY OTHER USER OF THE FastPagos SERVICE ON A PERSONAL BASIS.
                EXCEPT AS SPECIFICALLY AGREED BY FastPagos IN WRITING, THE
                FastPagos SERVICE AND OTHER FastPagos MATERIALS ARE PROVIDED “AS
                IS” AND FastPagos AND ITS LICENSORS MAKE NO OTHER WARRANTIES,
                EXPRESS OR IMPLIED, AND DISCLAIM ANY IMPLIED WARRANTY OF
                MERCHANTABILITY, NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR
                PURPOSE. FastPagos DOES NOT WARRANT THAT THE FastPagos SERVICE
                IS ENTIRELY ERROR-FREE. FastPagos’S AND ITS LICENSORS’ TOTAL
                LIABILITY TO YOU FOR ANY CLAIM OR DAMAGE ARISING OUT OF THESE
                TERMS, INCLUDING ANY USE OF OR INABILITY TO USE THE FastPagos
                SERVICE OR OTHER FastPagos MATERIALS, SHALL BE LIMITED TO DIRECT
                DAMAGES, WHICH SHALL NOT EXCEED THE TOTAL AGGREGATE AMOUNT OF
                $10. IN NO EVENT SHALL FastPagos OR ITS LICENSORS BE LIABLE FOR
                ANY LOST PROFITS, LOST DATA, INTERRUPTIONS OF BUSINESS, OR ANY
                INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OF OR
                INABILITY TO USE THE FastPagos SERVICE OR OTHER FastPagos
                MATERIALS, REGARDLESS OF WHETHER FastPagos HAS NOTICE OF THE
                POTENTIAL FOR SUCH LOSS OR DAMAGE. Some jurisdictions prohibit
                certain limitations of damages in consumer contracts, so the
                above limitations may be superseded by law in some
                jurisdictions.
              </li>
              <li className="mb-4 text-wrap">
                11. APPLICABLE LAWS. (a) FastPagos controls the FastPagos
                Service from its offices in the United States of America.
                FastPagos makes no representation that the FastPagos Materials
                are appropriate or available for use in other locations, and
                access to them from territories where their content is illegal
                is prohibited. Those who choose to access the FastPagos Service
                from other locations do so on their own initiative and are
                responsible for compliance with applicable local laws. (b) You
                may not use or export the FastPagos Materials other than as
                permitted by the Customer’s agreement with FastPagos. (c) These
                Terms shall be governed by the laws of the state of Utah,
                excluding conflicts of laws rules. You consent to the exclusive
                jurisdiction and venue of courts in Utah in all disputes arising
                out of or relating to these Terms. (d) FastPagos’s provision of
                the FastPagos Service and other FastPagos Materials and services
                is subject to existing laws and legal process, and nothing
                contained in these Terms limits FastPagos’s right to comply with
                governmental, court and law enforcement requests or requirements
                relating to your use of the FastPagos Service, other FastPagos
                Materials, or Customer Data.{" "}
              </li>
              <li className="mb-4 text-wrap">
                12. MISCELLANEOUS. Except as otherwise specified herein, these
                Terms constitute the entire agreement between you and FastPagos
                with respect to the FastPagos Service. Except for the Privacy
                Policy and any binding written contract between you and
                FastPagos, these Terms supersede all other prior or
                contemporaneous communications and understandings, whether
                electronic, oral or written, between you and FastPagos with
                respect to the FastPagos Service. If any provision of these
                Terms is found void or unenforceable, all other provisions and
                terms shall remain in full force and effect. The failure to
                enforce any right or provision of these Terms will not
                constitute a waiver of future enforcement of that right or
                provision.{" "}
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsScreen;

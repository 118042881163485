import React, { useState,useEffect } from "react";
import NewTransactionForm from "../NewTransactionForm/NewTransactionForm";
import { useDispatch, useSelector } from "react-redux";
import { setShowForm } from "../../actions/dropdownActions";
import TransactionTable from "../TransactionTable/TransactionTable";
import ShowPaymentLink from "../ShowPaymentLink/ShowPaymentLink";
import {
  getAllCustomerByUserIDRequest,
  getAllCustomerByUserIDSuccess,
  getAllCustomerByUserIDFailure,
  setSelectedBuyer,
} from "../../actions/customerActions";
import customerService from "../../services/customerService";
import { setShowExistingBuyer } from "../../actions/dropdownActions";

const TransactionManagement = () => {
  const { showForm, showPaymentLink } = useSelector((state) => state.dropdowns);
  //   const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();

  const getAllBuyersOfCurrentUser = async () => {
    try {
      dispatch(getAllCustomerByUserIDRequest());
      const response = await customerService.getAllCustomerByUserId();
      if (response.success === true) {
        dispatch(getAllCustomerByUserIDSuccess(response.data));
        // Check if there are any buyers available
        if (response.data.length > 0) {
          // Set the first buyer as selected
          dispatch(setSelectedBuyer(response.data[0]));
        }
      }
      return response;
    } catch (err) {
      dispatch(getAllCustomerByUserIDFailure(err.message));
    }
  };
  
  useEffect(() => {
    getAllBuyersOfCurrentUser();
  }, []);
  

  return (
    <div className="flex relative sm:flex-col">
      {/* trasaction filter section starts here*/}

      <div className="bg-white shadow sm:rounded-md flex sm:flex-col">
        <div className="flex sm:p-2 sm:flex-row sm:justify-between sm:items-center">
          <div className="">
            <div className="flex sm:flex-row sm:gap-x-3 sm:items-center">
              <span className="sm:text-xl">Transaction</span>
              <span className="sm:text-xs flex sm:text-end">Pay In</span>
            </div>
          </div>
          <div
            onClick={() => dispatch(setShowForm(true))}
            className="bg-blue-500 cursor-pointer  sm:rounded-lg text-white sm:p-3 sm:font-medium"
          >
            Create New
          </div>
        </div>
        <div>
          <ul className="sm:flex sm:flex-row sm:pb-2 ">
            <li className="sm:px-3  sm:text-xs sm:text-gray-500 sm:border-r-2">
              All
            </li>
            {/* <li className="sm:px-3  sm:text-xs sm:text-gray-500 sm:border-r-2">
              Succeeded
            </li>
            <li className="sm:px-3  sm:text-xs sm:text-gray-500 sm:border-r-2">
              Pending
            </li>
            <li className="sm:px-3  sm:text-xs sm:text-gray-500 sm:border-r-2">
              Refunded
            </li>
            <li className="sm:px-3  sm:text-xs sm:text-gray-500 sm:border-r-2">
              Failed
            </li> */}
          </ul>
        </div>
      </div>
      <div className="sm:mt-4">
        <TransactionTable />
      </div>
      <div className="absolute top-24 sm:w-full">
        {showForm === true && <NewTransactionForm />}
        {showPaymentLink === true && <ShowPaymentLink />}
      </div>
    </div>
  );
};

export default TransactionManagement;

import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { setShowPaymentLink } from "../../actions/dropdownActions";
import { useDispatch, useSelector } from "react-redux";
import paymentService from "../../services/paymentService";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";

export default function ShowPaymentLink() {
  const { newPayment } = useSelector((state) => state.paymnets);
  const [open, setOpen] = useState(true);
  const [copied, setCopied] = useState(false); // State to manage copied message
  const dispatch = useDispatch();

  const cancelButtonRef = useRef(null);

  const handleCancelClick = () => {
    dispatch(setShowPaymentLink(false));
  };

  const handleSendEmail = async () => {
    // Modify the createdAt field to dueDate
    const modifiedPayment = {
      ...newPayment,
      dueDate: moment(newPayment.createdAt).format("DD MMM YYYY"),
    };
    delete modifiedPayment.createdAt;

    toast.success("Invoice email is sent");
    // console.log("modifiedPayment===", modifiedPayment);

    const response = await paymentService.pdfSender(modifiedPayment);
    dispatch(setShowPaymentLink(false));
    return response;
  };

  const handleCopyLink = () => {
    // Copy the payment link URL to the clipboard
    // navigator.clipboard.writeText(newPayment.paymentLink);

    const textArea = document.createElement("textarea");
    textArea.value = newPayment.paymentLink;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      setCopied(true);
      toast.success("Link copied");
    } catch (err) {
      // console.error("Unable to copy to clipboard", err);
      toast.error("Link not copied");
    }
    document.body.removeChild(textArea);

    setCopied(true); // Set copied message visibility to true
    // Hide copied message after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(setShowPaymentLink(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 sm:w-full  text-center  sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Payment Link
                      </Dialog.Title>
                      <div className="mt-2 w-full ">
                        {/* Display the payment link and add a button to copy it */}
                        <div className="flex items-center justify-center">
                          <input
                            className="w-full text-center border rounded-lg p-2"
                            type="text"
                            value={newPayment.paymentLink}
                            readOnly
                          />
                          <button
                            className={`ml-2 px-4 py-2 rounded-lg focus:outline-none ${
                              copied
                                ? "bg-white border text-blue-500"
                                : "bg-blue-500 text-white"
                            }`}
                            onClick={handleCopyLink}
                          >
                            {copied ? "Copied!" : "Copy"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 gap-x-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border hover:border-blue-600 sm:mt-0 sm:w-auto focus:ring-4 focus:ring-blue-100"
                    onClick={handleCancelClick}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border hover:border-blue-600 sm:mt-0 sm:w-auto focus:ring-4 focus:ring-blue-100"
                    onClick={handleSendEmail}
                    ref={cancelButtonRef}
                  >
                    send Email
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React from "react";
import Profile from "../Profile/Profile";

const ProfileComponentsManage = () => {
  return (
    <div className="flex flex-col items-center justify-center sm:flex-col">
       
        <Profile />
    
     
    </div>
  );
};

export default ProfileComponentsManage;

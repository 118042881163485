import React, { useEffect } from "react";
import "./adminDashboardManagement.css";
import TransactionManagement from "../transactionManagement/TransactionManagement";



const AdminDashboardManagement = () => {






  return (
    <div className="adminDasboard_main flex flex-col align-center ">
     
     <TransactionManagement />

    </div>
  );
};

export default AdminDashboardManagement;

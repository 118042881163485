import React, { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { setCountryName } from "../../actions/dropdownActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown({ onChange, items, value }) {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");

  // Set the initial selected value when the component mounts
  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    } else if (items.length > 0) {
      setSelectedValue(items[0].name);
    }
  }, [items, value]);

  const handleCountryChange = (country) => {
    setSelectedValue(country.name);
    if (onChange) {
      onChange(country.name);
    }
  };

  return (
    <Listbox value={selectedValue} onChange={handleCountryChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              style={{ backgroundColor: "#F6F8FD" }}
              className="flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-100 mb-3 sm:mb-1 placeholder:text-grey-700 text-dark-grey-900 rounded-2xl"
            >
              <span className="flex items-center">
                <span className="block truncate">{selectedValue}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-start pr-2">
                {/* <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                /> */}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10  max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                {items.map((currency) => (
                  <Listbox.Option
                    key={currency.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-blue-500 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3"
                      )
                    }
                    value={currency}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {currency.name.toUpperCase()}
                          </span>
                        </div>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          ></span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
